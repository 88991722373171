import React, { useState, useRef, useLayoutEffect } from 'react';
import PanelManager from '../panel-manager';

import '../../scss/panel/ScreenshotPanel.scss';

const ImagesItem = React.memo(({ url, index, onClick, isCurrent }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      className={`img-wrapper ${isCurrent ? 'current' : ''}`}
      onClick={() => {
        onClick(index);
      }}
    >
      <img
        className={isLoaded ? '' : 'hidden'}
        src={url}
        onLoad={() => {
          setIsLoaded(true);
        }}
        alt="app-screen-shot"
      />
      <div className={`placeholder ${isLoaded ? 'hidden' : ''}`}>
        <i data-icon="file-photo" />
      </div>
    </div>
  );
});

const ScreenshotPanel = React.memo(({ currentIndex, images, updateIndex }) => {
  const element = useRef(null);
  const viewerWrapper = useRef(null);
  const [viewerWrapperLeft, setViewerWrapperLeft] = useState(0);

  useLayoutEffect(
    () => {
      setViewerWrapperLeft(getLeftSpacing());
    },
    [viewerWrapper.current, currentIndex]
  );

  const getLeftSpacing = () => {
    if (viewerWrapper.current === null) {
      return 0;
    }
    const currentLeft = viewerWrapper.current.getBoundingClientRect().left;
    const selectedImgWrapper = document.querySelectorAll('.img-wrapper')[
      currentIndex
    ];
    const fullWidth = element.current.getBoundingClientRect().width;
    const { left, width } = selectedImgWrapper.getBoundingClientRect();
    return currentLeft - left + (fullWidth - width) / 2;
  };

  const viewerWrapperStyle = {
    left: `${viewerWrapperLeft}px`,
  };

  return (
    <div className="screenshot-panel-wrapper" ref={element}>
      <div className="header">
        <div
          className="icon-wrapper"
          onClick={() => {
            PanelManager.back();
          }}
        >
          <div className="icon" />
        </div>
      </div>
      <div
        className="viewer-wrapper "
        style={viewerWrapperStyle}
        ref={viewerWrapper}
      >
        {images.map((value, index) => (
          <ImagesItem
            key={value}
            url={value}
            index={index}
            onClick={updateIndex}
            isCurrent={index === currentIndex}
          />
        ))}
      </div>
    </div>
  );
});

export default ScreenshotPanel;
