import React from 'react';
import AppIcon from '@/component/AppIcon';

const _ = navigator.mozL10n.get;

const SearchListItem = ({ result, handleClick }) => (
  <kai-1line-listitem text={result.name} onClick={handleClick(result.id)}>
    <div slot="listitem-left-slot" className="app-icon-wrapper">
      <AppIcon type={result.type} icon={result.thumbnail_url} />
    </div>
  </kai-1line-listitem>
);

const SearchNoResult = ({ loading }) => (
  <div className="message">
    {loading ? <div /> : <p>{_('search-no-result')}</p>}
  </div>
);

const ErrorMessage = () => (
  <div className="message">
    <p>{_('search-went-wrong')}</p>
  </div>
);

const SearchList = ({ searchResults, loading, handleClick, error }) => {
  if (error)
    return (
      <div className="search-list">
        <ErrorMessage />
      </div>
    );
  return (
    <div className="search-list">
      {searchResults.length > 0 ? (
        searchResults.map(result => (
          <SearchListItem
            result={result}
            key={result.id}
            handleClick={handleClick}
          />
        ))
      ) : (
        <SearchNoResult loading={loading} />
      )}
    </div>
  );
};

export default SearchList;
