import React, { Component } from 'react';
import throttle from 'lodash.throttle';
import AppStore from '@/app-store';
import PanelManager from '@/panel-manager';
import { isAppsMenuFull, handleMenuFull } from './pagePanelUtils';
import { ACTIONS } from './constant';
import analyticsHelper from '../../helper/analytics-helper';

import {
  InfoRow,
  ActionRow,
  ProgressBar,
  DetailList,
  Description,
  ReadMoreOverlay,
  ScreenShotsSection,
  YMALSection,
  UnverifiedWarning,
} from './component';

import '@/../scss/panel/PagePanel.scss';

class PagePanel extends Component {
  constructor(props) {
    super(props);
    const app = AppStore.findAppById(this.props.appId);
    this.pagePanelRef = React.createRef();
    this.contentRef = React.createRef();
    this.state = {
      app,
      isContentCollapsed: null,
    };
    window.addEventListener('appstore:change', this.updateAppInState());
  }

  componentDidMount() {
    const { id, version } = this.state.app;
    // handle the case when first app should autoDownload when mounted
    const { shouldDirectDownload } = this.props;
    if (shouldDirectDownload) {
      this.updateAppInState(shouldDirectDownload)();
    }

    this.updateOverflowStatus();

    if (!app.isBookmark) {
      this.getGraphQLInfo();
    } else {
      // this.handleAnalyticsYmalReady();
    }

    analyticsHelper.postAppViewDone({
      event_name: 'store_app_view_done',
      app_id: id,
      app_version: version,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // when the app changed, go back to top
    if (this.props.appId !== prevProps.appId) {
      this.reset();
    }

    if (
      this.props.appId !== prevProps.appId ||
      this.props.shouldDirectDownload !== prevProps.shouldDirectDownload
    ) {
      // XXX: since the pagePanel is never unmount when KaiStore is in the foreground
      // we check if the current app required autoDownload when appId or shouldDirectDownload changes
      const { shouldDirectDownload } = this.props;
      this.updateAppInState(shouldDirectDownload)();
    }

    if (
      prevState.isContentCollapsed !== null &&
      this.state.isContentCollapsed === null
    ) {
      this.updateOverflowStatus();
    }

    if (prevState.isContentCollapsed !== this.state.isContentCollapsed) {
      PanelManager.updateHistoryDetail({
        isContentCollapsed: this.state.isContentCollapsed,
      });
    }
  }

  get ymalApps() {
    const { ymal } = this.state.app.info;
    if (ymal && ymal.length > 0) {
      const apps = ymal.map(manifestURL =>
        AppStore.findAppByManifest(manifestURL)
      );
      return apps.filter(app => app !== null);
    }
    return [];
  }

  getAction(app) {
    if (app.isBookmark) {
      const { pinStatus } = app.status;
      return pinStatus ? ACTIONS.OPEN : ACTIONS.PIN;
    }
    const { installed, updatable, downloading, purchased } = app.status;
    if (downloading) return ACTIONS.STOP;
    if (installed) return updatable ? ACTIONS.UPDATE : ACTIONS.OPEN;
    const { paid, unitPrice } = app.info;
    if (paid && !purchased) return unitPrice ? ACTIONS.BUY : ACTIONS.DISABLEBUY;
    return ACTIONS.GET;
  }

  handleAction = action => async () => {
    const { app } = this.state;
    const { info } = app;
    switch (action) {
      // XXX: was not able to test BUY behavior
      case ACTIONS.BUY:
      case ACTIONS.GET: {
        const isMenuFull = await isAppsMenuFull();
        if (isMenuFull) {
          handleMenuFull();
          break;
        }
        const { paid, productId } = info;
        if (paid) {
          if (productId) {
            app.purchaseApp();
          } else {
            console.warn('Paid app without product_id');
          }
        } else {
          app.enableApp();
        }
        break;
      }
      case ACTIONS.OPEN:
        app.launch();
        break;
      case ACTIONS.PIN:
        app.pinBookmark();
        break;
      case ACTIONS.STOP:
        app.cancelDownload();
        break;
      case ACTIONS.UPDATE:
        app.update();
        break;
      // maybe need to toast a message
      case ACTIONS.DISABLEBUY:
      default:
        break;
    }
  };

  handleAutoDownload = () => {
    const { app } = this.state;
    const action = this.getAction(app);
    if (app.isBookmark) {
      // XXX: keep previous logic that open the bookmark regardless its pin status
      this.handleAction(ACTIONS.OPEN)();
    } else {
      this.handleAction(action)();
    }
  };

  updateAppInState = (shouldAutoDownload = false) => () => {
    const updatedApp = AppStore.findAppById(this.props.appId);
    // Take updatedApp info and send an analytical event
    if (updatedApp.manifestURL !== this.state.app.manifestURL) {
      analyticsHelper.postAppViewDone({
        event_name: 'store_app_view_done',
        app_id: updatedApp.id,
        app_version: updatedApp.info.version,
      });
    }
    if (shouldAutoDownload) {
      this.setState(
        {
          app: updatedApp,
        },
        () => {
          this.handleAutoDownload();
          this.getGraphQLInfo();
        }
      );
    } else {
      this.setState(
        {
          app: updatedApp,
        },
        () => {
          this.getGraphQLInfo();
        }
      );
    }
  };

  isOverflown = element => {
    if (!element) {
      return false;
    }
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  };

  updateOverflowStatus = () => {
    requestAnimationFrame(() => {
      if (this.isOverflown(this.contentRef.current)) {
        this.setState({ isContentCollapsed: true });
      } else {
        this.setState({ isContentCollapsed: false });
      }
    });
  };

  reset = () => {
    const prevIsContentCollapsed = PanelManager.panelDetails.isContentCollapsed;
    if (typeof prevIsContentCollapsed !== 'undefined') {
      this.setState({ isContentCollapsed: prevIsContentCollapsed });
    } else {
      this.setState({ isContentCollapsed: null });
    }
    const prevScrollTop = PanelManager.panelDetails.scrollTop;
    if (typeof prevScrollTop !== 'undefined') {
      requestAnimationFrame(() => {
        this.pagePanelRef.current.scrollTop = prevScrollTop;
      });
    } else {
      this.pagePanelRef.current.scrollTop = 0;
    }
  };

  getGraphQLInfo() {
    const { app } = this.state;
    if (app.info.ymal) {
      // this.handleAnalyticsYmalReady();
      return;
    }
    AppStore.fetchGraphQL({ manifestURL: app.manifestURL }, true)
      .then(() => {
        const updatedApp = AppStore.findAppById(this.props.appId);
        this.setState({
          app: updatedApp,
        });

        return null;
      })
      .catch(err => {
        console.warn('Error: App not found through graphQL');
        return err;
      })
      .then(error => {
        // this.handleAnalyticsYmalReady(error);
      });
  }

  // TODO: touch stream didn't implement ymal related code
  // /**
  //  * Update app and ymals for analytics-helper and send store_app_view_init event
  //  * @param {String} error - error message if GraphQL fails to get ymalApps
  //  * @private
  //  */
  // handleAnalyticsYmalReady = (error = null) => {
  //   const { app } = this.state;
  //   const logInfo = {
  //     app,
  //     ymalApps: this.ymalApps || [],
  //   };

  //   if (error) {
  //     logInfo.errorDetail = error;
  //   }
  //   analyticsHelper.updateAppViewInitInfo(logInfo);
  //   analyticsHelper.sendAppViewInit();
  //   analyticsHelper.sendAppViewDone(app);
  // };

  handleScroll = () => {
    PanelManager.updateHistoryDetail({
      scrollTop: this.pagePanelRef.current.scrollTop,
    });
  };

  render() {
    const { app, isContentCollapsed } = this.state;
    if (!app || typeof app !== 'object') {
      return <></>;
    }
    const { info, status, localized } = app;
    const {
      supportedLanguages,
      type,
      icon,
      url,
      manifestURL,
      paid,
      screenshots,
      categoryList,
      description,
      progressState,
      size,
      developer,
      version,
      unitPrice,
    } = info;
    const { purchased } = status;
    const bookmarkURL = url || manifestURL;
    const action = this.getAction(app);
    return (
      <div
        className="Page"
        ref={this.pagePanelRef}
        onScroll={throttle(this.handleScroll, 500)}
      >
        <div className="top-section">
          <div className="detail-header-wrapper">
            <InfoRow
              subtitle={localized.subtitle}
              name={localized.name}
              type={type}
              icon={icon}
              bookmarkURL={bookmarkURL}
            />
            <ActionRow
              action={action}
              paid={paid}
              purchased={purchased}
              handleAction={this.handleAction}
              unitPrice={unitPrice}
            />
          </div>
          <ProgressBar action={action} progressState={progressState} />
        </div>
        <div
          className={`readmore-wrapper
            ${isContentCollapsed ? 'collapsed' : ''}
            ${isContentCollapsed === false ? 'expanded' : ''}
            `}
          ref={this.contentRef}
        >
          <Description description={description} />
          {action !== ACTIONS.PIN && (
            <DetailList
              type={type}
              size={size}
              developer={developer}
              categoryList={categoryList}
              supportedLanguages={supportedLanguages}
              version={version}
            />
          )}
          <ReadMoreOverlay
            isVisible={isContentCollapsed}
            onClick={() => {
              this.setState({ isContentCollapsed: false });
            }}
          />
        </div>
        <ScreenShotsSection screenshots={screenshots} />
        <UnverifiedWarning type={type} />
        <YMALSection apps={this.ymalApps} />
      </div>
    );
  }
}

export default PagePanel;
