import React, { Component } from 'react';
import AppStore from '@/app-store';
import PanelManager from '@/panel-manager';
import debounce from 'lodash.debounce';
import { SearchBar, Empty, SearchList } from './component';

import 'scss/panel/SearchPanel.scss';

class SearchPanel extends Component {
  handleSearch = debounce(async query => {
    const { searchIn } = this.props;
    try {
      const apps = await AppStore.handleSearch(query, searchIn);
      this.setState({ results: apps, loading: false, error: false });
    } catch (e) {
      this.setState({ results: [], loading: false, error: true });
    }
  }, 800);

  constructor(props) {
    super(props);
    this.state = {
      // TODO: use object to store query and results for different searchIn props
      query: '',
      results: [],
      error: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { searchIn } = this.props;
    const { searchIn: prevSearchIn } = prevProps;
    // only clean up query when searchIn prop changes
    if (searchIn !== prevSearchIn) {
      this.handleQuery('');
    }
  }

  handleQuery = query => {
    if (query === '') {
      this.handleSearch.cancel();
      this.setState({ query, results: [], loading: false, error: false });
    } else {
      this.handleSearch(query);
      this.setState({ query, results: [], loading: true, error: false });
    }
  };

  handleClick = id => () => {
    PanelManager.request({
      panel: 'page',
      details: { id },
    });
  };

  render() {
    const { searchIn, activate } = this.props;
    const { query, results, loading, error } = this.state;
    return (
      <div className="search-panel">
        <SearchBar
          handleQuery={this.handleQuery}
          activate={activate}
          query={query}
          searchIn={searchIn}
        />
        {query === '' ? (
          <Empty searchIn={searchIn || PanelManager.currentSection} />
        ) : (
          <SearchList
            error={error}
            searchResults={results}
            loading={loading}
            handleClick={this.handleClick}
          />
        )}
      </div>
    );
  }
}

export default SearchPanel;
