import { ManualUpdateCoreAppsCommand } from 'kaistore-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';

class CoreAppUpdateHelper {
  constructor() {}

  upsert(manifestURLs) {
    const command = new ManualUpdateCoreAppsCommand({
      detail: {
        manifestURLs,
      },
    });
    MessageSender.send(command);
  }
}

export default new CoreAppUpdateHelper();
