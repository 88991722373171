import React from 'react';

import AppItem from '@/component/AppItem';

const _ = navigator.mozL10n.get;

export default ({ apps }) => {
  if (!apps || apps.length === 0) {
    return null;
  }
  return (
    <div className="ymal-section">
      <kai-separator label={_('you-may-also-like')} />
      {apps.map((application, index) => (
        <AppItem
          key={application.id}
          app={application}
          order={index}
          isDownloading={application.status.downloading}
        />
      ))}
    </div>
  );
};
