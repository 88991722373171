import { GAMES_CATEGORY_CODE } from '@/constants';
import DialogHelper from '@/helper/dialog-helper';
import ToastHelper from '@/helper/toast-helper';
import { ACCOUNT_COLORS } from '@/constants';

const _ = navigator.mozL10n.get;

// URL "https://example.com" is the same as "https://example.com/",
// so we should normalize URL here.
export const normalizeURL = bookmarkURL => {
  return new URL(bookmarkURL).href;
};

export const isURLValid = bookmarkURL => {
  if (bookmarkURL === undefined) return false;
  const regexQuery =
    '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
  const url = new RegExp(regexQuery, 'i');
  return url.test(bookmarkURL);
};

export const isAppDataValid = app => {
  if (!app.id || !app.manifest_url) {
    console.error(
      `The APP must have id and manifest_url. Wrong data: ${JSON.stringify(
        app
      )}`
    );
    return false;
  }
  return true;
};

export const hasGameCategory = searchApp =>
  searchApp.category_list.includes(GAMES_CATEGORY_CODE);

export const getIcon = icons => {
  const ICON_DEFAULT_SIZE = '84';
  if (icons[ICON_DEFAULT_SIZE]) return icons[ICON_DEFAULT_SIZE];
  const sizes = Object.keys(icons);
  if (sizes.length > 0) {
    const closestSize = sizes.reduce(
      (acc, cur) =>
        Math.abs(cur - ICON_DEFAULT_SIZE) < Math.abs(acc - ICON_DEFAULT_SIZE)
          ? cur
          : acc
    );
    return icons[closestSize];
  }
  return null;
};

// THINK: when should use locale from mozApp and remoteApp
export const handleLocale = (locales, defaultLocale) => {
  if (!locales || !defaultLocale) return {};
  const phoneLangCode = navigator.mozL10n.language.code;
  return (
    locales[phoneLangCode] ||
    locales[defaultLocale] ||
    locales['en-US'] ||
    locales.default ||
    Object.values(locales)[0]
  );
};

export const removeEndOfLine = input => {
  try {
    const endOfLineRegex = /\\[rn]/g;
    return input.replace(endOfLineRegex, '');
  } catch {
    return input;
  }
};

export const formatRemoteManifestURL = manifestURL => {
  const isEncoded = decodeURI(manifestURL) !== manifestURL;
  if (isEncoded) {
    return manifestURL;
  }
  // trim manifest url to make sure there is no space in it.
  return encodeURI(manifestURL.trim());
};

export const generatePWAInfo = application => {
  if (application.pwaInfo) {
    return application.pwaInfo;
  }
  if (
    (application.info.type !== 'pwa' && application.info.type !== 'openpwa') ||
    application.info.icon !== null
  ) {
    return null;
  }
  const { name } = application.localized;
  if (!name) {
    return null;
  }
  let acronym = name[0].toUpperCase();
  const matches = name.match(/\b(\w)/g);
  if (matches) {
    acronym = matches
      .join('')
      .toUpperCase()
      .substring(0, 2);
  }
  if (application.info.theme) {
    return {
      theme: application.info.theme,
      acronym,
    };
  }
  // generate a 0 - 19 index by using app's name and acronym
  const themeIndex =
    (acronym.split('').reduce((acc, char) => {
      return acc + char.charCodeAt(0);
    }, 0) +
      name.length) %
    20;
  return {
    theme: ACCOUNT_COLORS[themeIndex],
    acronym,
  };
};
