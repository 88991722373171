const ACTIONS = {
  BUY: 'buy',
  GET: 'get',
  UPDATE: 'update',
  OPEN: 'open',
  STOP: 'stop',
  PIN: 'pin',
  DISABLEBUY: 'disableBuy',
};

const SIZE_OPTIONS = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export { ACTIONS, SIZE_OPTIONS };
