export const SEARCH_CRITERIA = {
  APPS: 'apps',
  ALL: 'all',
  GAMES: 'games',
  DEVELOPER: 'developer',
  DISCOVER: 'discover',
};

export const GAMES_CATEGORY_CODE = 10;
export const EXPLORE_CATEGORY_CODE = 200;
export const APPS_CATEGORY_CODE = 400;

export const PROMOTIONAL_ADS_CODE = 210;
export const TOP_APPS_CODE = 230;
export const TOP_GAMES_CODE = 240;
export const WHATS_NEW_CODE = 250;
export const OPEN_PWA_CODE = 410;

export const ALL_APPS_EXCLUDED_GAMES_CODE = 'ALL_APPS_EXCLUDED_GAMES';

export const ALL_APPS_EXCLUDED_OPEN_PWA_CODE = 'ALL_APPS_EXCLUDED_OPEN_PWA';

export const APP_HTTP_ORIGIN = 'http://kaios-store.localhost';

export const KAIOS_HOMEPAGE = 'https://www.kaiostech.com/';

export const ASYNCSTORAGE_KEY = {
  DOWNLOADED_APPS: 'downloaded-apps',
};

export const SECTION_NAME = {
  APPS: 'apps',
  GAMES: 'games',
  MORE: 'more',
  DISCOVER: 'discover',
};

export const ACCOUNT_COLORS = [
  '#FF4F1A',
  '#FF8111',
  '#A6482A',
  '#604A4A',
  '#31799C',
  '#E92E20',
  '#DA0051',
  '#AF0779',
  '#840FA2',
  '#8000FF',
  '#2948F3',
  '#0073E6',
  '#00A6FB',
  '#00BBD3',
  '#019688',
  '#00BF60',
  '#75CF0B',
  '#BDD000',
  '#FFC300',
  '#FE9A24',
];
