import React, { Component } from 'react';
import NetworkHelper from '@/helper/network-helper';

import 'scss/panel/LoadingPanel.scss';
import 'scss/status.scss';
const _ = navigator.mozL10n.get;

const LoadingPanelUI = ({ status, errorText = {} }) => (
  <div className="content-wrapper">
    {status === 'loading' ? (
      <div>
        <div className="logo-wrapper">
          <div className="logo" />
        </div>
        <div className="loader-wrapper">
          <div className="loader" />
        </div>
      </div>
    ) : (
      <div>
        <div className="status-img-wrapper">
          <div className="status-img" data-status={status} />
        </div>
        <div className="error-msg-wrapper">
          <div className="title">{_(errorText.title)}</div>
          <div className="content">{_(errorText.content)}</div>
        </div>
      </div>
    )}
  </div>
);

class LoadingPanel extends Component {
  constructor(props) {
    super(props);
    this.online = navigator.onLine;
  }

  shouldComponentUpdate() {
    const currentOnline = NetworkHelper.online;

    if (this.online !== currentOnline) {
      this.online = currentOnline;
      return true;
    }
    return false;
  }

  render() {
    const { online } = this;

    const status = online ? 'loading' : 'offline';
    const errorText = online
      ? {}
      : { title: 'loading-error', content: 'please-check-your-network' };
    return (
      <div
        className={`Loading ${status === 'loading' ? 'with-bg' : ''}`}
        tabIndex="1"
      >
        <LoadingPanelUI status={status} errorText={errorText} />
      </div>
    );
  }
}

export default LoadingPanel;
