import React from 'react';
import AppIcon from '@/component/AppIcon';

const _ = navigator.mozL10n.get;

const DeveloperApp = ({ icon, type, name, appId, handleClick }) => (
  <div className="developer-app" onClick={handleClick(appId)}>
    <div className="icon">
      <AppIcon icon={icon} type={type} />
    </div>
    <div className="app-name">{name}</div>
  </div>
);

const DeveloperAppsSection = ({ apps, handleClick }) => {
  return (
    <div className="apps-section">
      <div className="developer-app-title">{_('apps-from-developer')}</div>
      <div className="apps">
        {apps.map(({ type, id: appId, name, thumbnail_url: icon }) => (
          <DeveloperApp
            icon={icon}
            type={type}
            name={name}
            key={appId}
            appId={appId}
            handleClick={handleClick}
          />
        ))}
      </div>
    </div>
  );
};

export default DeveloperAppsSection;
