import debounce from 'lodash.debounce';

import { deviceUtils } from '../device-utils';
import Account from '../account';
import { postThroughClient } from './http-request-helper';

class AnalyticsHelper {
  postAppViewInit = debounce(info => {
    const logInfo = {
      ...info,
      store_version: this.storeVersion,
      device_utc: Date.now(),
      connection_type: deviceUtils.connection.type,
    };
    this.postData(logInfo);
  }, 1500);

  constructor() {
    this.apiName = 'store';
    this.referralMeta = {};
    this.storeVersion = null;
    this.browsedHistory = [];
  }

  init({ version }) {
    this.storeVersion = version;
  }

  setReferralMeta(idObj = {}) {
    if (idObj.ad_id || idObj.cs_id) {
      this.referralMeta = idObj;
    }
  }

  setReferralMetaByActivity(mozActivityTask) {
    if (mozActivityTask && mozActivityTask.option) {
      const optionData = mozActivityTask.option.data;
      if (optionData && optionData.adId) {
        this.setReferralMeta({ ad_id: optionData.adId });
      } else if (optionData && optionData.csId) {
        this.setReferralMeta({ cs_id: optionData.csId });
      }
    }
  }

  saveBrowsedHistory(config = {}) {
    const { panel } = config;

    if (panel) {
      switch (panel) {
        case 'applist':
          this.browsedHistory.push({
            tab: config.details.category.code,
            device_utc: Date.now(),
          });
          break;
        case 'more':
          this.browsedHistory.push({
            tab: 'More',
            device_utc: Date.now(),
          });
          break;
        default:
          break;
      }
    }
  }

  clearBrowsedHistory() {
    this.browsedHistory = [];
  }

  postData(data) {
    // The url will be 'https://analytics.test.kaiostech.com/v1/store'
    // if in test environment
    const apiUri = deviceUtils.settings.get('apps.analyticsEventBaseUrl');
    const url = `${apiUri}/${this.apiName}`;
    const logInfo = this._composeSharedData(data);

    return postThroughClient(url, { params: logInfo });
  }

  _composeSharedData(data) {
    const logInfo = { ...data };
    if (Account.id !== null) {
      logInfo.account_id = Account.id;
    }

    if (logInfo.event_name && logInfo.event_name.endsWith('init')) {
      logInfo.referral_meta = this.referralMeta;
      logInfo.user_journey = this.browsedHistory;
      this.clearBrowsedHistory();
    }

    return logInfo;
  }

  postAppActionData(info) {
    const logInfo = {
      ...info,
      store_version: this.storeVersion,
      device_utc: Date.now(),
      connection_type: deviceUtils.connection.type,
    };
    this.postData(logInfo);
  }

  postAppViewDone(info) {
    const logInfo = {
      ...info,
      store_version: this.storeVersion,
      device_utc: Date.now(),
      connection_type: deviceUtils.connection.type,
    };
    this.postData(logInfo);
  }

  postUpdateAllAppData(application) {
    const logInfo = {
      event_name: 'store_app_update_init',
      app_id: application.id,
      app_version: application.version,
      referral_meta: this.referralMeta,
      store_version: this.storeVersion,
      device_utc: Date.now(),
      connection_type: deviceUtils.connection.type,
    };
    this.postData(logInfo);
  }
}

export default new AnalyticsHelper();
