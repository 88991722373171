import { MessageSender } from 'web-message-helper';
import { ShowToastCommand } from 'kaistore-post-messenger/src/commands';
import { Toast } from 'kaistore-post-messenger/src/models';

class ToastHelper {
  constructor() {
    this._ = navigator.mozL10n.get;
  }

  showMsg(l10nId, interpolateObject = null, alwaysShow = false) {
    if (!l10nId) return;
    // interpolateObject: https://developer.mozilla.org/en-US/docs/Archive/B2G_OS/API/L10n/get
    const message = interpolateObject
      ? this._(l10nId, interpolateObject)
      : this._(l10nId);
    // Avoid to show toast when app is in background and allShow is not set to true.
    // XXX: can't really do anything if the visibilityState changed after calling showToast
    if (document.visibilityState !== 'hidden' || alwaysShow)
      MessageSender.send(
        new ShowToastCommand({
          detail: new Toast({ message }),
        })
      );
  }
}

export default new ToastHelper();
