/** TODO:
 * 1. handleLocale in one place: handle locale when init with remoteApp or mozApp
 * miniManifest > search > mozApp updateManifest > mozApp manifest > remoteApp??
 * */
import {
  mozAppEvent as constMozAppEvent,
  internalApps,
  preloadedApps,
} from 'kaistore-post-messenger/lib/constants';
import {
  InstallCommand,
  UninstallCommand,
  UpdateAppCommand,
  LaunchAppCommand,
  CancelDownloadCommand,
  PINBookmarkCommand,
} from 'kaistore-post-messenger/src/commands';
import { Bookmark } from 'kaistore-post-messenger/src/models';
import { MessageSender } from 'web-message-helper';

import AppStore from '@/app-store';
import PanelManager from '@/panel-manager';
import ToastHelper from '@/helper/toast-helper';
import MozAppHelper from '@/helper/mozapp-helper';
import PaymentHelper from '@/helper/payment-helper';
import analyticsHelper from '@/helper/analytics-helper';

import { SUPPORTED_TYPE, STATUS } from './constants';
import * as appStoreUtils from './app-store-utils';
import {
  getHigherVersion,
  checkIsUpdatableApp,
  formatRemoteManifestURL,
  openURL,
  trimLocales,
} from '@/utils';

class Application {
  constructor(manifestURL) {
    this.manifestURL = manifestURL.trim();
    this._mozApp = null;
    this._remoteApp = {};
    this._searchApp = {};
    this._graphQLApp = {};
    this._status = {
      [STATUS.DOWNLOADING]: false,
      [STATUS.INSTALLED]: false,
      [STATUS.UPDATABLE]: false,
      [STATUS.PURCHASED]: false,
      [STATUS.PIN]: false,
    };
    this.coreManualUpdate = false;
    this.pwaInfo = null;
  }

  get status() {
    return this._status;
  }

  get isInstalled() {
    return Boolean(this._mozApp && this._mozApp.installState === 'installed');
  }

  get id() {
    return (
      (this.searchApp && this.searchApp.id) ||
      (this.remoteApp && this.remoteApp.id) ||
      (this.graphQLApp && this.graphQLApp.id)
    );
  }

  get version() {
    return (
      (this.searchApp && this.searchApp.version) ||
      (this.remoteApp && this.remoteApp.version) ||
      (this.graphQLApp && this.graphQLApp.version) ||
      (this.mozApp && this.mozApp.manifest && this.mozApp.manifest.version)
    );
  }

  get isBookmark() {
    return (
      (this.remoteApp && this.remoteApp.type === 'bookmark') ||
      (this.searchApp && this.searchApp.type === 'bookmark') ||
      (this.graphQLApp && this.graphQLApp.type === 'bookmark')
    );
  }

  get isHosted() {
    const hostedTypes = ['hosted', 'pwa', 'openpwa'];
    return (
      (this.remoteApp && hostedTypes.includes(this.remoteApp.type)) ||
      (this.searchApp && hostedTypes.includes(this.searchApp.type)) ||
      (this.graphQLApp && hostedTypes.includes(this.graphQLApp.type))
    );
  }

  get mozApp() {
    return this._mozApp;
  }

  get isInternal() {
    return Object.values(internalApps).includes(this.id);
  }

  get isPreloaded() {
    return Object.values(preloadedApps).includes(this.id);
  }

  get isRemote() {
    return (
      Object.keys(this._remoteApp).length > 0 ||
      Object.keys(this._searchApp).length > 0 ||
      Object.keys(this._graphQLApp).length > 0
    );
  }

  /*
   * The setter of mozApp is called by updateMozApp
   * where the new mozApp got from navigator.mozApps.mgmt should replace the current mozApp
   * We should manually clear the handlers to prevent multiple handlers are defined.
  */
  set mozApp(mozApp) {
    if (this._mozApp) {
      this._mozApp.ondownloadavailable = null;
      this._mozApp.onprogress = null;
      this._mozApp.ondownloadapplied = null;
      this._mozApp.ondownloaderror = null;
    }
    this._mozApp = mozApp;
  }

  get remoteApp() {
    return this._remoteApp;
  }

  set remoteApp(app) {
    this._remoteApp = app;
    this.pwaInfo = appStoreUtils.generatePWAInfo(this);
  }

  get searchApp() {
    return this._searchApp;
  }

  // FIXME: temp solution before unifying locale handling
  get mozAppLocale() {
    if (this.mozApp) {
      const { locales, default_locale: defaultLocale } = this.mozApp.manifest;
      return appStoreUtils.handleLocale(locales, defaultLocale);
    }
    return {};
  }

  set searchApp(searchApp) {
    this._searchApp = {
      id: searchApp.id,
      icon: searchApp.thumbnail_url,
      subtitle: searchApp.summary,
      category_list: searchApp.category_list,
      name: searchApp.name,
      description: searchApp.description,
      manifest_url: searchApp.manifest_url,
      paid: searchApp.paid,
      screenshots: searchApp.screenshots,
      size: searchApp.size,
      type: searchApp.type,
      version: searchApp.version,
      product_id: searchApp.product_id,
      price: searchApp.price,
      currency: searchApp.currency,
      theme: searchApp.theme,
    };
    this.pwaInfo = appStoreUtils.generatePWAInfo(this);
  }

  get graphQLApp() {
    return this._graphQLApp;
  }

  set graphQLApp(graphQLApp) {
    this._graphQLApp = {
      id: graphQLApp.id,
      icon: appStoreUtils.getIcon(graphQLApp.icons) || null,
      category_list: graphQLApp.category_list || [],
      name: graphQLApp.name,
      description: graphQLApp.description,
      manifest_url: graphQLApp.manifest_url,
      paid: graphQLApp.paid,
      screenshots: graphQLApp.screenshots || {},
      size: graphQLApp.size,
      type: graphQLApp.type,
      version: graphQLApp.version,
      product_id: graphQLApp.product_id,
      developer: graphQLApp.developer,
      ymal: graphQLApp.ymal
        ? graphQLApp.ymal.map(app => {
            return app.manifest_url;
          })
        : [],
      supportedLanguages: graphQLApp.supported_languages,
      default_locale: graphQLApp.default_locale,
      locales: graphQLApp.locales,
      theme: graphQLApp.theme || null,
    };
    this.pwaInfo = appStoreUtils.generatePWAInfo(this);
  }

  get info() {
    const { searchApp, remoteApp, graphQLApp, mozApp } = this;
    let updateManifestLocales = {};
    if (mozApp && mozApp.updateManifest) {
      updateManifestLocales = appStoreUtils.handleLocale(
        mozApp.updateManifest.locales,
        mozApp.updateManifest.default_locale
      );
    }
    const { subtitle, name, description } = {
      ...remoteApp,
      ...graphQLApp,
      ...searchApp,
      ...updateManifestLocales,
    };
    const updatedDescription = appStoreUtils.removeEndOfLine(description);
    const progressState =
      mozApp && mozApp.progress && mozApp.downloadSize
        ? Math.floor(mozApp.progress / mozApp.downloadSize) * 100
        : null;
    const getValue = (key, defaultValue) => {
      return (
        remoteApp[key] ||
        searchApp[key] ||
        graphQLApp[key] ||
        (mozApp && mozApp.updateManifest && mozApp.updateManifest[key]) ||
        defaultValue
      );
    };
    const getUnitPrice = () => {
      const price = getValue('price', null);
      if (price !== null) {
        return `${getValue('currency', '')} $${price}`;
      }
      return '';
    };
    const getIcon = () => {
      const icon = getValue('icon', null);
      const { pwaInfo } = this;
      if (icon === null && pwaInfo) {
        return {
          backgroundColor: pwaInfo.theme,
          text: pwaInfo.acronym,
        };
      }
      return icon;
    };
    return {
      type: getValue('type', 'web'),
      icon: getIcon(),
      categoryList: getValue('category_list', []),
      screenshots: getValue('screenshots', {}),
      manifestURL: getValue('manifest_url', null),
      paid: getValue('paid', false),
      size: getValue('packaged_size', null),
      developer: getValue('developer', {}),
      subtitle,
      name,
      description: updatedDescription,
      supportedLanguages: getValue('supported_languages', []),
      progressState,
      url: getValue('url', null),
      version: getValue('version', null),
      productId: getValue('product_id', null),
      ymal: graphQLApp.ymal || null,
      defaultLocale: getValue('default_locale', 'en-US'),
      locales: getValue('locales', null),
      display: getValue('display', null),
      price: getValue('price', null),
      currency: getValue('currency', ''),
      unitPrice: getUnitPrice(),
      theme: getValue('theme', null),
      bgs: getValue('bgs', null),
    };
  }

  get localized() {
    const { locales, defaultLocale } = this.info;
    const langCode =
      navigator.mozL10n.language.code || defaultLocale || 'en-US';
    if (locales) {
      return trimLocales(locales, langCode);
    }
    const { display: name, description, subtitle } = this.info;
    return { name, description, subtitle };
  }

  updateStatus(newStatus, shouldPublish = true, detail) {
    this._status = { ...this.status, ...newStatus };
    if (shouldPublish) {
      if (detail) {
        this.publish('appstore:change', detail);
      } else {
        this.publish('appstore:change');
      }
    }
  }

  updateMozApp(mozApp) {
    this.mozApp = mozApp;
    // reset updatable to false before checking again
    this.updateStatus({ [STATUS.UPDATABLE]: false }, false);
    this.checkUpdate();
  }

  // logic from appstore.initRemoteApps
  initRemoteApp(app) {
    if (!SUPPORTED_TYPE.includes(app.type)) return;
    if (app.type === 'bookmark') {
      if (appStoreUtils.isURLValid(app.url)) {
        const appInfo = {
          url: appStoreUtils.normalizeURL(app.url),
          icon: appStoreUtils.getIcon(app.icons),
        };
        this.remoteApp = { ...app, ...appInfo };
        return;
      }
      console.error(`missing url property: ${JSON.stringify(app)}`);
      return;
    }
    if (!appStoreUtils.isAppDataValid(app)) {
      return;
    }

    const appInfo = {
      manifest_url: app.manifest_url.trim(),
      bgs: app.bgs || {},
      icon: appStoreUtils.getIcon(app.icons),
    };
    if (app.category_list === undefined) {
      appInfo.category_list = [app.category];
    }
    this.remoteApp = { ...app, ...appInfo };

    // In case, launcher has ability to un-install app,
    // so we need to clean up local storage at cold launch.
    const options = {
      manifestURL: app.manifest_url,
    };
    this.syncInstalledApp();
  }

  syncInstalledApp() {
    if (this.mozApp && !this.isInternal) {
      if (this.mozApp.downloading) {
        // scenario: the mozApp is inited before remoteApp
        this.handleInstall(constMozAppEvent.ON_PROGRESS);
      } else {
        // Set as false to prevent publishing a large amount of events
        this.updateStatus({ [STATUS.INSTALLED]: true }, false);
        const installedOptions = {
          version: this.mozApp.manifest.version,
          manifestURL: this.manifestURL,
        };
      }
    }
  }

  // from appstore.checkUpdate;
  // did not migrate ondownloadsuccess and ondownloaderror since this function should just 'check' if update is available
  // these two handlers should be added in application.update instead
  checkUpdate() {
    if (!this.mozApp) return;

    if (!this.mozApp.ondownloadavailable) {
      this.mozApp.ondownloadavailable = () => {
        this.updateStatus({ [STATUS.UPDATABLE]: true }, true, {
          type: 'update-number-of-updatable-apps',
        });
      };
    }
  }

  update() {
    if (!this.mozApp) return;
    // isDownloadPreparing: a flag to show the period between user click update button and the app starts to being downloaded, since mozApp.downloading
    this.mozApp.isDownloadPreparing = true;
    return new Promise((resolve, reject) => {
      this.sendAppStatusLog('store_app_update_init');
      this.updateStatus({ [STATUS.DOWNLOADING]: true });
      if (this.core) {
        this.coreManualUpdate = true;
      }

      const command = new UpdateAppCommand({
        detail: {
          manifestURL: this.manifestURL,
        },
      });
      MessageSender.send(command, success => {
        if (success) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  handleUpdate(mozAppEvent) {
    switch (mozAppEvent) {
      case constMozAppEvent.ON_DOWNLOAD_AVAILABLE:
        if (checkIsUpdatableApp(this)) {
          this.updateStatus({ [STATUS.UPDATABLE]: true });
        }
        break;
      case constMozAppEvent.ON_DOWNLOAD_APPLIED:
        this.handleUpdatedSuccess();
        this.sendAppStatusLog('store_app_update_done');
        break;
      case constMozAppEvent.ON_DOWNLOAD_SUCCESS:
        this.handleDownloadSuccess();
        break;
      case constMozAppEvent.ON_DOWNLOAD_ERROR:
        this.handleDownloadError();
        break;
      default:
        console.error(
          `handleUpdate is not able to handle mozAppEvent: ${mozAppEvent}`
        );
        break;
    }
  }

  handleUpdatedSuccess() {
    const {
      manifest: { locales, default_locale: defaultLocale },
    } = this.mozApp;
    const { name: appName } = this.info;
    ToastHelper.showMsg('app-updated', { appName }, true);
    const status = {
      [STATUS.DOWNLOADING]: false,
      [STATUS.UPDATABLE]: false,
    };
    this.updateStatus(status, true, {
      type: 'update-number-of-updatable-apps',
    });
    this.sendAppStatusLog('store_app_update_done');
  }

  handleInstall(mozAppEvent) {
    if (this.isInstalled) {
      this.handleDownloadSuccess();
      this.sendAppStatusLog('store_app_install_done');
      return;
    }

    if (mozAppEvent === constMozAppEvent.ON_DOWNLOAD_APPLIED) {
      this.handleDownloadSuccess();
      this.sendAppStatusLog('store_app_install_done');
    } else if (mozAppEvent === constMozAppEvent.ON_DOWNLOAD_ERROR) {
      this.handleDownloadError();
      this.sendAppStatusLog('store_app_install_failed');
    } else {
      this.updateStatus({ [STATUS.DOWNLOADING]: true });
    }
  }

  cancelDownload() {
    const command = new CancelDownloadCommand({
      detail: {
        manifestURL: this.manifestURL,
      },
    });
    if (this.status.updatable) {
      ToastHelper.showMsg('update-canceled');
      this.sendAppStatusLog('store_app_update_cancel_init');
    } else {
      this.mozApp = null;
      ToastHelper.showMsg('download-stopped');
      this.sendAppStatusLog('store_app_install_cancel_init');
    }
    MessageSender.send(command);
  }

  // logic from appstore.handleUninstall
  uninstall() {
    if (!this.mozApp) return;
    const newStatus = {
      [STATUS.DOWNLOADING]: false,
      [STATUS.INSTALLED]: false,
      [STATUS.UPDATABLE]: false,
    };

    this.mozApp = null;
    this.updateStatus(newStatus);
  }

  handleUninstall() {
    // clear up app props.
    this.mozApp = null;

    this.updateStatus({
      [STATUS.DOWNLOADING]: false,
      [STATUS.UPDATABLE]: false,
    });
  }

  launch() {
    if (this.isBookmark) {
      const { url, manifestURL } = this.info;
      window.open(url || manifestURL, '_blank', 'remote=true');
    } else {
      const command = new LaunchAppCommand({
        detail: {
          manifestURL: this.manifestURL,
        },
      });
      MessageSender.send(command);
    }
  }

  // used in this.handleDownloadError and in this.enableApp
  handleDownloadError(isCancelled = false) {
    // TODO
    // const isCancelled = errorName === DOWNLOAD_CANCEL_ERROR;
    if (isCancelled === false) {
      if (PanelManager.isAppDetailPage) {
        ToastHelper.showMsg('install-or-update-fail');
      } else {
        ToastHelper.showMsg('install-or-update-fail-in-background');
      }
    }
    // Don't clear mozApp if update failed
    if (!this.status.installed) {
      this.mozApp = null;
    }
    // Reset downloading status, keep previous status of installed and
    // updatable.
    this.updateStatus({ [STATUS.DOWNLOADING]: false });
  }

  handleDownloadSuccess() {
    // TODO: should review overall unused info to reduce memory usage
    // https://bugzilla.kaiostech.com/show_bug.cgi?id=60865
    // Remove unused info: description & locales.description
    const { mozApp } = this;
    const formattedMozApp = MozAppHelper.releaseUnusedInfo(mozApp);
    this.mozApp = formattedMozApp;
    const { paid } = this.info;
    const status = {
      [STATUS.DOWNLOADING]: false,
      [STATUS.INSTALLED]: true,
      [STATUS.UPDATABLE]: false,
      [STATUS.PURCHASED]: paid,
    };
    this.updateStatus(status);

    // Save downloaded app record into asyncStorage
    if (!this.isInternal && !this.isPreloaded) {
      AppStore.addDownloadAppRecord(this);
    }

    // Remove the order info from indexedDB
    if (paid) {
      const { productId } = this.info;
      window.asyncStorage.removeItem(`kaipay-to-kaistore-${productId}`);
    }

    const { id } = this;
    if (PanelManager.isAtAppDetailPage(id) === false) {
      const l10nId = this.status.updatable ? 'app-updated' : 'app-downloaded';
      const { name: appName } = this.info;
      ToastHelper.showMsg(l10nId, { appName });
    }
    this.sendAppStatusLog('store_app_install_done');
  }

  publish(eventName, detail) {
    const event = detail
      ? new CustomEvent(eventName, {
          detail,
        })
      : new CustomEvent(eventName);
    window.dispatchEvent(event);
  }

  /**
   * scenarios:
   * 1. bookmarkListener receive 'added' event
   * 2. when app-store starting up, it will sync the pinStatus in bookmarkDB to remote apps
   */
  updatePinStatus(pinStatus) {
    if (this.isBookmark) this.status.pinStatus = pinStatus;
  }

  pinBookmark() {
    if (!this.isBookmark) {
      console.error('Not a bookmark app, pinBookmark() should not be called');
      return;
    }
    const { icon, url } = this.info;
    const { name } = this.localized;
    const command = new PINBookmarkCommand({
      detail: new Bookmark({ url, icon, name }),
    });
    MessageSender.send(command, success => {
      if (success === true) {
        // TODO
        // loggerHelper.postBookmarkData(bookmarkEvent.PIN, this.id, this.info);
      }
    });
  }

  // FIXME: follow the old naming of purchased now, should rename is as isPurchased
  updatePurchased(isPurchased) {
    if (!this.isBookmark) this.status.purchased = isPurchased;
  }

  // handle paid apps
  purchaseApp() {
    if (!this.remoteApp && !this.searchApp && !this.graphQLApp) return;
    const { paid, productId, name } = this.info;
    if (paid && productId) {
      const paymentHelper = new PaymentHelper();
      // Check if the product(app) has been purchased before
      paymentHelper
        .getPurchasedByProductId(productId)
        .then(product => {
          if (product) return product.transaction_id;
          return null;
        })
        .catch(error => {
          console.warn(
            `Cannot find the purchased record with product_id=${productId}`,
            error
          );
          return null;
        })
        .then(transactionId => {
          if (transactionId) {
            // If the transactionId is found, get the receiptToken to enable app and skip payment
            paymentHelper
              .getReceiptByTransactionId(transactionId)
              .then(({ receipt_token: receiptToken }) => {
                const jwt = { receiptToken };
                this.enableApp(jwt);
              })
              .catch(error =>
                console.error('Failed get receipt by transactionId', error)
              );
          } else {
            // If the app hasn't been purchased before, enter the purchase process
            paymentHelper
              .pay({
                id: productId,
                name,
              })
              .then(jwt => {
                this.updatePurchased(true);
                this.enableApp(jwt);
              })
              .catch(error => console.error('Failed payment process: ', error));
          }
        });
    }
  }

  enableApp(jwt = null) {
    if (!this.remoteApp && !this.searchApp && !this.graphQLApp) return;
    this.updateStatus({ [STATUS.DOWNLOADING]: true });
    this.install(jwt).catch(result => {
      this.handleDownloadError();
      console.warn(`install error: ${JSON.stringify(result)}`);
    });
    this.sendAppStatusLog('store_app_install_init');
  }

  sendAppStatusLog(event_name = '') {
    const { id, version } = this;
    const logInfo = {
      event_name,
      app_id: id,
      app_version: version,
    };

    analyticsHelper.postAppActionData(logInfo);
  }

  install(jwt = null) {
    this.updateStatus({ [STATUS.DOWNLOADING]: true });
    const command = new InstallCommand({
      detail: {
        isHosted: this.isHosted,
        manifestURL: this.manifestURL,
        jwt,
      },
    });

    return new Promise((resolve, reject) => {
      MessageSender.send(command, (success, detail) => {
        if (success === true) {
          resolve();
        } else {
          this.sendAppStatusLog('store_app_install_failed');
          reject(detail.error);
        }
      });
    });
  }

  uninstall() {
    const command = new UninstallCommand({
      detail: {
        manifestURL: this.manifestURL,
      },
    });
    this.sendAppStatusLog('store_app_uninstall_init');
    MessageSender.send(command);
  }

  handleBookmarkUpdate(pin, silent) {
    this.updateStatus({ [STATUS.PIN]: pin });
    if (!silent) {
      const messageL10nId = pin
        ? 'pin-bookmark-completely'
        : 'unpin-bookmark-completely';
      ToastHelper.showMsg(messageL10nId);
    }
  }
}

export default Application;
