import React, { Component } from 'react';

import '@/../scss/panel/PagePanel.scss';
import AppStore from '@/app-store';
import {
  ActionRow,
  Description,
  DetailList,
  InfoRow,
  ProgressBar,
  ScreenshotsButton,
  ReadMoreOverlay,
} from './component';

import ACTIONS from './constants';

class UpdatePagePanel extends Component {
  constructor(props) {
    super(props);
    const { manifestURL } = this.props;
    this.state = {
      application: AppStore.findAppByManifest(manifestURL),
      isContentCollapsed: null,
    };
    window.addEventListener('appstore:change', this.getApplication);
    this.pagePanelRef = React.createRef();
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    this.updateOverflowStatus();
  }

  componentDidUpdate(prevProps, prevState) {
    const { manifestURL: prevManifestURL } = prevProps;
    const { manifestURL } = this.props;
    if (manifestURL !== prevManifestURL) {
      this.getApplication();
      this.reset();
    }
    if (
      prevState.isContentCollapsed !== null &&
      this.state.isContentCollapsed === null
    ) {
      this.updateOverflowStatus();
    }
  }

  getApplication = () => {
    const { manifestURL } = this.props;
    const application = AppStore.findAppByManifest(manifestURL);
    this.setState({ application });
  };

  getAction = (downloading, updatable, reboot) => {
    if (downloading) return ACTIONS.STOP;
    if (!updatable) return ACTIONS.OPEN;
    return reboot ? ACTIONS.UPDATEANDRESTART : ACTIONS.UPDATE;
  };

  /**
   * TODO: get core from app.core
   * https://bugzilla.kaiostech.com/show_bug.cgi?id=87133, refer to UpdatePanelUI.js
   */

  isAppCore = ({ manifest, updateManifest }) => {
    const combinedManifest = { ...manifest, ...updateManifest };
    const { core } = combinedManifest;
    return core;
  };

  updateOverflowStatus = () => {
    requestAnimationFrame(() => {
      if (this.isOverflown(this.contentRef.current)) {
        this.setState({ isContentCollapsed: true });
      } else {
        this.setState({ isContentCollapsed: false });
      }
    });
  };

  isOverflown = element => {
    if (!element) {
      return false;
    }
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  };

  reset = () => {
    this.pagePanelRef.current.scrollTo(0, 0);
    this.setState({ isContentCollapsed: null });
  };

  render() {
    const { application, isContentCollapsed } = this.state;
    if (!application) return <></>;
    const { manifest, updateManifest } = application.mozApp;
    const { downloading, updatable } = application.status;
    const { icon } = application.info;
    const { name, subtitle, description } = application.localized;
    const combinedManifest = { ...manifest, ...updateManifest };
    const {
      type,
      locales,
      screenshots,
      size,
      developer,
      category_list: categoryList,
      version,
      reboot,
    } = combinedManifest;
    const action = this.getAction(downloading, updatable, reboot);
    const isCore = this.isAppCore(application.mozApp);
    return (
      <div className="Page" ref={this.pagePanelRef}>
        <div className="top-section">
          <div className="detail-header-wrapper">
            <InfoRow
              type={type}
              icons={icon}
              name={name}
              subtitle={subtitle}
              isCore={isCore}
            />
            <ActionRow
              action={action}
              application={application}
              isCore={isCore}
            />
          </div>
          <ProgressBar action={action} application={application} />
        </div>
        <div
          className={`readmore-wrapper
            ${isContentCollapsed ? 'collapsed' : ''}
            ${isContentCollapsed === false ? 'expanded' : ''}
            `}
          ref={this.contentRef}
        >
          <Description description={description} reboot={reboot} />
          <DetailList
            size={size}
            version={version}
            developer={developer}
            categoryList={categoryList}
            locales={locales}
            isCore={isCore}
          />
          <ReadMoreOverlay
            isVisible={isContentCollapsed}
            onClick={() => {
              this.setState({ isContentCollapsed: false });
            }}
          />
        </div>
        {!isCore &&
          Object.values(screenshots).length > 0 && (
            <ScreenshotsButton screenshots={screenshots} />
          )}
      </div>
    );
  }
}

export default UpdatePagePanel;
