import { commandList } from 'kaistore-post-messenger';
import { mozAppsEvents as constMozAppsEvents } from 'kaistore-post-messenger/lib/constants';
import AppStore from '@/app-store';
import Account from '@/account';
import { mozActivityManager } from '@/mozactivity-manager';

const receiveMessage = event => {
  const { action, detail } = event.detail;
  switch (action) {
    case commandList.MOZAPP_UPDATE: {
      const { manifestURL, mozApp, mozAppEvent, mozAppsEvent } = detail;
      const app = AppStore.findAppByManifest(manifestURL);
      if (!app) {
        return;
      }

      app.mozApp = mozApp;
      AppStore.publish('appstore:change');

      if (mozAppsEvent === constMozAppsEvents.INSTALL) {
        app.handleInstall(mozAppEvent);
      } else if (mozAppsEvent === constMozAppsEvents.UNINSTALL) {
        app.handleUninstall();
      } else if (mozAppsEvent === constMozAppsEvents.UPDATE) {
        app.handleUpdate(mozAppEvent);
      }
      break;
    }
    case commandList.ACCOUNT_EVENT: {
      const { event: e, data } = detail;
      Account.handleEvent(e, data);
      break;
    }
    case commandList.BOOKMARK_UPDATE: {
      const { pin, url, silent } = detail;
      const bookmark = AppStore.findBookmarkByUrl(url);
      if (bookmark) {
        bookmark.handleBookmarkUpdate(pin, silent);
      }
      break;
    }
    case commandList.REQUEST_RELOAD: {
      window.location.reload();
      break;
    }
    case commandList.TASK_QUEUE_UPDATE: {
      mozActivityManager.saveToQueue(detail);
      break;
    }
    case commandList.NOTIFY_MANUAL_UPDATE_DONE: {
      const { manifestURLs } = detail;
      appStore.handleUpdateMozApps(manifestURLs);
      break;
    }
    default:
      console.warn(`This ${action} action is not supported. Details: ${event}`);
      break;
  }
};

export default receiveMessage;
