import React from 'react';
import AppIcon from '@/component/AppIcon';

const InfoRow = ({ type, icon, name, subtitle, isCore }) => (
  <div className="info-row">
    <div className="left">
      <AppIcon type={type} icon={icon} />
    </div>
    <div className="right">
      <h2 className="title">{name}</h2>
      <div className="subtitle sub-2">
        {/* XXX: developer.name is only 'KaiOS', spec uses 'KaiOS Tech Inc.'  */}
        {isCore ? 'KaiOS Tech Inc.' : subtitle}
      </div>
    </div>
  </div>
);

export default InfoRow;
