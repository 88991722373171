import { createQuerystringFromParams } from '../utils';
import { deviceUtils } from '../device-utils';
import { fetchThroughClient } from './http-request-helper';

/*
* ?cu=40440-2AAQUS0
*  &imei=123123123123123
*  &simMCC=null&simMNC=null&currentMCC=null&currentMNC=null
*/

class DynamicCategory {
  constructor() {
    this.apiName = 'categories';
    this.categories = [];
  }

  getCategories() {
    // toekn: let assertion.kid , assertion.macKey
    const cu = deviceUtils.settings.get('deviceinfo.cu');
    const defaultServiceId = deviceUtils.connection.defaultServiceId;
    const commsInfo = deviceUtils.connection;

    const params = {
      cu,
      defaultServiceId,
      imei: deviceUtils.connection.imei,
      ...commsInfo,
    };

    const baseURL = deviceUtils.settings.get('apps.serviceCenterUrl');
    return (
      fetchThroughClient(
        `${baseURL}/${this.apiName}${createQuerystringFromParams(params)}`,
        { useDeviceInfo: true }
      )
        .then(
          response => {
            return response;
          },
          error => {
            console.error(`categories: ${error.statusText}`);
            // pass empty array.
            return [];
          }
        )
        // init displayName
        .then(cates => {
          const lang = navigator.mozL10n.language.code || '';
          const categories = cates.map(cate => ({
            ...cate,
            displayName: (cate.locales && cate.locales[lang]) || cate.name,
          }));
          this.categories = categories;
          return categories;
        })
    );
  }

  getCategoriesByCodes(codes) {
    return this.categories.filter(category => codes.includes(category.code));
  }

  getCategoryByCode(code) {
    return this.categories.find(category => category.code === code);
  }

  getCategoryChildrenByCode(code) {
    const category = this.getCategoryByCode(code);
    const { children } = category;
    return this.getCategoriesByCodes(children);
  }

  get remoteCategories() {
    return this.categories;
  }
}

export default new DynamicCategory();
