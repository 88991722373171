import React from 'react';
import DialogHelper from '@/helper/dialog-helper';
import CoreAppUpdateHelper from '@/helper/core-app-update-helper';
import AppItem from './AppItem';
import analyticsHelper from '@/helper/analytics-helper';

const _ = navigator.mozL10n.get;

const UpdateAllButton = ({ handleClick, buttonDisabled = false }) => (
  <div className="btn-wrapper">
    <kai-pillbutton
      level="secondary"
      text={_('update-all')}
      onClick={handleClick}
      disabled={buttonDisabled}
    />
  </div>
);

const AppListSection = ({ title, handleClick, applist, buttonDisabled }) => (
  <div className="section">
    <kai-separator label={_(title)} />
    <UpdateAllButton
      handleClick={handleClick}
      buttonDisabled={buttonDisabled}
    />
    {applist.map(application => (
      <AppItem key={application.manifestURL} application={application} />
    ))}
  </div>
);

const ThirdPartyAppListSection = ({ title, applist }) => {
  const handleClick = () =>
    applist.forEach(application => application.update());
  const buttonDisabled = applist.some(
    ({ status, mozApp }) => status.downloading || mozApp.isDownloadPreparing
  );
  return (
    <AppListSection
      title={title}
      handleClick={handleClick}
      applist={applist}
      buttonDisabled={buttonDisabled}
    />
  );
};

const CoreAppListSection = ({ title, applist }) => {
  const isRebootRequired = ({ mozApp: { manifest, updateManifest } }) => {
    const combinedManifest = { ...manifest, ...updateManifest };
    const { reboot } = combinedManifest;
    return reboot;
  };
  const hasRebootApp = applist.some(app => isRebootRequired(app));
  // system app will handle core app update, add to-be-updated-apps to 'core-app-update' dataStore
  const handleUpdate = () => {
    const manifestURLs = applist.map(application => application.manifestURL);
    CoreAppUpdateHelper.upsert(manifestURLs);
    applist.forEach(application => {
      analyticsHelper.postUpdateAllAppData(application);
    });
    // TODO: handle after update is done
  };
  const handleRebootUpdate = () => {
    const updateRestartDialogOptions = {
      title: _('update-all'),
      secondarybtntext: _('cancel'),
      primarybtntext: _('update'),
      message: _('update-restart-message'),
      onPrimaryBtn: () => {
        handleUpdate();
        DialogHelper.hideDialog();
      },
      onSecondaryBtn: DialogHelper.hideDialog,
    };
    DialogHelper.showDialog(updateRestartDialogOptions);
  };
  const handleClick = hasRebootApp ? handleRebootUpdate : handleUpdate;

  return (
    <AppListSection title={title} handleClick={handleClick} applist={applist} />
  );
};

const UpdatePanelUI = ({ number, updatableApps }) => {
  /* Note: updatableCoreApps includes system and core apps
   * For now, get core from app.manifest.core and app.updateManifest.core,
   * TODO: get core from app.core https://bugzilla.kaiostech.com/show_bug.cgi?id=87133
  */
  const isAppCore = ({ mozApp: { manifest, updateManifest } }) => {
    const combinedManifest = { ...manifest, ...updateManifest };
    const { core } = combinedManifest;
    return core;
  };

  const updatableCoreApps = updatableApps.filter(application =>
    isAppCore(application)
  );
  const updatable3rdPartyApps = updatableApps.filter(
    application => !isAppCore(application)
  );

  return (
    <div className="update-panel Appsview">
      <div className="header-wrapper">
        <kai-header primarytitle={_('update')} />
        {number > 0 && (
          <div className="update-indicator">
            <span>{number}</span>
          </div>
        )}
      </div>
      <div className="section-wrapper">
        {updatableCoreApps.length > 0 && (
          <CoreAppListSection
            title="system-software-update"
            applist={updatableCoreApps}
          />
        )}
        {updatable3rdPartyApps.length > 0 && (
          <ThirdPartyAppListSection
            title="app-update"
            applist={updatable3rdPartyApps}
          />
        )}
      </div>
    </div>
  );
};

export default UpdatePanelUI;
