import React, { Component } from 'react';

import PanelManager from '@/panel-manager';
import MorePanelHelper from '@/helper/more-panel-helper';
import Account from '@/account';

import AppStore from '@/app-store';

import '@/../scss/panel/MorePanel.scss';

const _ = navigator.mozL10n.get;
class MorePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: Account.id,
      numberOfUpdatableApps: AppStore.updatableApplications.length,
    };

    window.addEventListener('account:updateId', this.handleAccountIdUpdate);
    window.addEventListener('appstore:change', this.handleStoreChanged);
  }

  handleStoreChanged = () => {
    const updatableNumber = AppStore.updatableApplications.length;
    const { numberOfUpdatableApps } = this.state;
    if (updatableNumber !== numberOfUpdatableApps)
      this.setState({ numberOfUpdatableApps: updatableNumber });
  };

  handleAccountIdUpdate = event => {
    const newId = event.detail.id;
    this.setState({ accountId: newId });
  };

  handleItemClick = item => async () => {
    const { url, callback, panel, id, title } = item;
    if (url) {
      window.open(url);
    } else if (callback) {
      callback();
    } else if (panel) {
      const isDownloadHistoryPage = id === 'download-history';
      const downloadedApps = await AppStore.downloadedOrPurchasedApps();
      const hasDownloadedApps = downloadedApps.length > 0;

      if (isDownloadHistoryPage && hasDownloadedApps === false) {
        this.requestErrorPage('no-download-history', title);
      } else {
        PanelManager.request({ panel: panel.name, details: panel.details });
      }
    }
  };

  requestErrorPage(status, titleId) {
    PanelManager.request({
      panel: 'error',
      details: { status, origin: _(titleId) },
    });
  }

  render() {
    const { title, type, items } = MorePanelHelper.getDetail(this.props.page);
    const { numberOfUpdatableApps } = this.state;
    return (
      <div className="more-panel">
        <kai-header primarytitle={title} type={type || null} />
        {items.map(item => {
          return (
            <div key={item.id} className="list-item">
              <ListItem
                text={_(item.title)}
                subtext={item.subtitlePlain}
                onClick={this.handleItemClick(item)}
                id={item.id}
              >
                <i slot="listitem-left-slot" data-icon={item.icon} />
                <i slot="listitem-right-slot" data-icon="forward" />
              </ListItem>
              {item.id === 'update' && (
                <UpdateIndicator number={numberOfUpdatableApps} />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

// TODO: moved UI components to seperated files
const ListItem = props =>
  props.subtext ? (
    <kai-2lines-listitem {...props}>{props.children}</kai-2lines-listitem>
  ) : (
    <kai-1line-listitem {...props}>{props.children}</kai-1line-listitem>
  );

const UpdateIndicator = props => {
  const { number } = props;
  return (
    number > 0 && (
      <div className="update-indicator">
        <span>{number}</span>
      </div>
    )
  );
};

export default MorePanel;
