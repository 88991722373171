import PaymentHelper from './payment-helper';
import { deviceUtils } from '../device-utils';
import { fetchThroughClient } from './http-request-helper';
import { createQuerystringFromParams } from '@/utils';

import { OPEN_PWA_CODE } from '@/constants';

class AppListHelper {
  constructor() {
    this._deviceIMEI = deviceUtils.connection.imei || '0123456789';
    this._deviceInfo = deviceUtils.settings;
    this._simInfo = deviceUtils.connection;
    this._locale = navigator.mozL10n.language.code || 'en-US';
  }

  get apiURI() {
    const apiURIKey = 'apps.serviceCenter.microServiceUrl';
    const apiURI = deviceUtils.settings.get(apiURIKey);
    return apiURI;
  }

  async fetchAppList(category, pageInfo = null, excludedCategory = null) {
    // get all apps if category is not supplied
    const appListParams = this._getAppListParams(category, excludedCategory);
    const params =
      pageInfo && !pageInfo.isExplore
        ? {
            ...appListParams,
            page_size: pageInfo.pageSize,
            page_num: pageInfo.pageNumber,
          }
        : appListParams;
    try {
      const apiName = category === OPEN_PWA_CODE ? 'apps/pwa' : 'apps';
      const querystring = createQuerystringFromParams(params);
      const URL = `${this.apiURI}/${apiName}${querystring}`;
      const result = await fetchThroughClient(URL, {
        toRemoveControlChars: true,
        useDeviceInfo: true,
      });
      return result;
    } catch (e) {
      this.broadcast(
        'server:error',
        'connection-error',
        `Server ERROR code: ${JSON.stringify(e)}`
      );
    }
  }

  getPurchasedAppIds() {
    return new Promise(resolve => {
      const helper = new PaymentHelper();
      helper
        .getAllPurchased()
        .then(result => {
          const appIds = result
            .filter(
              ({
                related_good_id: relatedGoodId,
                transaction_id: transactionId,
              }) => relatedGoodId && transactionId
            )
            .map(({ related_good_id: relatedGoodId }) => relatedGoodId);
          resolve(appIds);
        })
        .catch(() => {
          // XXX: should we also return [] when 401 error?
          resolve([]);
        });
    });
  }

  // Obtain the params for fetch app list.
  _getAppListParams(category, excludedCategory) {
    const bookmarkSupported = this.isBookmarkSupported();
    const defaultServiceId = this._deviceInfo.get('ril.data.defaultServiceId');
    const params = {
      bookmark: bookmarkSupported,
      link: true,
      defaultServiceId,
      imei: this._deviceIMEI,
      locale: this._locale,
    };
    const deviceParams = {};

    this._deviceInfo.forEach((value, key) => {
      if (key.indexOf('deviceinfo') >= 0) {
        const keyInfo = key.split('.');
        const valueWithoutSpace = value.replace(/ /g, '_');

        deviceParams[keyInfo[keyInfo.length - 1]] = valueWithoutSpace;
      }
    });
    const cate = category && category !== OPEN_PWA_CODE ? { category } : {};
    const excludedCate = excludedCategory ? { exclude: excludedCategory } : {};
    return {
      ...params,
      ...this._simInfo,
      ...deviceParams,
      ...cate,
      ...excludedCate,
    };
  }

  // Only support in v3.0.
  // apiURI should be "https://example.com" or "https://example.com/v3.0"
  isBookmarkSupported() {
    const apiURI = this._deviceInfo.get('apps.serviceCenterUrl');
    const URIArray = apiURI.split('/');
    const apiVersion = URIArray[3];

    if (apiVersion && apiVersion.indexOf('v3.0') >= 0) {
      return true;
    }
    return false;
  }

  // [name] => server:update or server:error
  broadcast(name, details, msg = '') {
    const event = new CustomEvent(name, {
      detail: details,
    });
    window.dispatchEvent(event);

    if (name === 'server:error') {
      console.error(msg);
    }
  }

  fetchPaidAppInfo(apps) {
    if (apps.length === 0) {
      return Promise.resolve();
    }
    // Only need to show price and currency for non-purchased paid app.
    const nonPurchasedPaidApps = apps.filter(
      application =>
        application.info.paid &&
        !application.status.purchased &&
        application.info.categoryList.length > 0
    );
    const categories = nonPurchasedPaidApps.map(
      application => application.info.categoryList[0]
    );
    const uniqueCategories = [...new Set(categories)];
    const promises = uniqueCategories.map(category =>
      this.fetchAppList(category)
    );
    return Promise.all(promises).catch(error => {
      console.warn(
        `failed to get all information from ${uniqueCategories}`,
        error
      );
    });
  }
}

export default AppListHelper;
