import { accountEvent } from 'kaistore-post-messenger/lib/constants';
import { MessageSender } from 'web-message-helper';
import {
  OpenAccountCommand,
  GetAccountVersionCommand,
  RequestTokenCommand,
} from 'kaistore-post-messenger/src/commands';
import { tokenType } from 'kaistore-post-messenger/lib/constants';

class Account {
  constructor() {
    this.version = null;
    this.isLoggedIn = false;
    this.afterLoginRequest = null;
    this.id = null;
  }

  init() {
    this.getVersion();
  }

  handleEvent(type, detail) {
    switch (type) {
      case accountEvent.ON_ACCOUNT_API_MISSING:
        this.emit('account:missing');
        break;
      case accountEvent.ON_LOGIN:
        this.isLoggedIn = true;
        if (detail.accountId) {
          this.id = detail.accountId;
        }
        this.emit('account:login');
        break;
      case accountEvent.ON_LOGOUT:
        this.isLoggedIn = false;
        this.id = null;
        this.emit('account:logout');
        break;
      case accountEvent.ON_READY:
        this.emit('account:ready');
        break;
      case accountEvent.ON_ERROR:
        this.emit('account:error', { detail });
        break;
      default:
        break;
    }
  }

  emit(name, detail) {
    window.dispatchEvent(new CustomEvent(name, detail));
  }

  /*
   * [section]
   * - accounts: Open settings APP and switch to account tab.
   * - fxa: Open KaiOS account entry page via settings APP.
   */
  openAccount(section = 'accounts') {
    return new Promise((resolve, reject) => {
      const command = new OpenAccountCommand({
        detail: {
          section,
        },
      });
      MessageSender.send(command, (success, result) => {
        if (success === true && this.isLoggedIn) {
          resolve(result);
        } else {
          reject(result);
        }
      });
    });
  }

  /*
   * Check KaiOS account version, the new KaiOS account will
   * be appied in v3.0.0. More details please refer to
   * https://bugzilla.kaiostech.com/show_bug.cgi?id=71284
   */
  getVersion() {
    MessageSender.send(new GetAccountVersionCommand(), (success, result) => {
      if (success === true) {
        const { version } = result;
        this.version = version;
      } else {
        this.version = null;
      }
    });
  }

  requestToken(forceRefresh = false) {
    return new Promise(resolve => {
      MessageSender.send(
        new RequestTokenCommand({ detail: { refresh: forceRefresh } }),
        (success, detail) => {
          if (success) {
            const { type } = detail;
            this.isLoggedIn = type === tokenType.ACCOUNT;
            resolve({ hasToken: true });
          } else {
            resolve({ hasToken: false });
          }
        }
      );
    });
  }
}

export default new Account();
