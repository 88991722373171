import React, { Component } from 'react';
import AppStore from '@/app-store';
import { SEARCH_CRITERIA } from '@/constants';

import PanelManager from '@/panel-manager';
import { DeveloperLinkSection, DeveloperAppsSection } from './component';
import 'scss/panel/DeveloperPanel.scss';

class DeveloperPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
    };
  }

  componentDidMount() {
    const {
      developer: { name },
    } = this.props;
    if (name) {
      this.getAppsByDeveloperName(name);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      developer: { name },
    } = this.props;
    const {
      developer: { name: prevName },
    } = prevProps;
    if (name !== prevName) {
      this.setState({ apps: [] });
      this.getAppsByDeveloperName(name);
    }
  }

  getAppsByDeveloperName = async name => {
    try {
      if (name) {
        const apps = await AppStore.handleSearch(
          name,
          SEARCH_CRITERIA.DEVELOPER
        );
        console.warn(name, apps);
        this.setState({ apps });
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleClick = id => () => {
    PanelManager.request({
      panel: 'page',
      details: { id },
    });
  };

  render() {
    const { apps } = this.state;
    const { developer } = this.props;
    const hasLinkSection =
      (developer.supports && Object.keys(developer.supports).length > 0) ||
      developer.url ||
      developer.privacy;
    const hasAppSection = apps.length > 0;
    console.warn(apps, hasLinkSection, hasAppSection);
    return (
      <div className="dev-panel">
        <div className="developer-name">{developer.name}</div>
        <div className="developer-section">
          <div className="scrollbar-wrapper">
            {hasLinkSection && <DeveloperLinkSection developer={developer} />}
            {/* TODO: validate developer */}
            {hasAppSection && hasLinkSection && <div className="divider" />}
            {hasAppSection && (
              <DeveloperAppsSection
                apps={apps}
                handleClick={this.handleClick}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DeveloperPanel;
