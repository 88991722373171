class DialogHelper {
  constructor() {
    this.optionsList = [];
    this.show = false;
  }

  showDialog(options) {
    this.optionsList.push(options);
    if (this.show === false) {
      this.dispatchNextDialog();
    }
  }

  hideDialog = () => {
    this.show = false;
    const eventName = 'dialog:hide';
    const event = new CustomEvent(eventName, {});
    window.dispatchEvent(event);
    this.dispatchNextDialog();
  };

  dispatchNextDialog() {
    const nextOptions = this.optionsList.shift();
    if (!nextOptions) {
      return;
    }
    this.show = true;
    const eventName = 'dialog:show';
    const event = new CustomEvent(eventName, {
      detail: { options: nextOptions },
    });
    window.dispatchEvent(event);
  }
}

export default new DialogHelper();
