import { MessageSender } from 'web-message-helper';
import { RequestDeviceInfoCommand } from 'kaistore-post-messenger/src/commands';
import { DeviceInfo } from 'kaistore-post-messenger/src/models';

class DeviceUtils {
  constructor() {
    this.connection = {};
    this.settings = new Map();
  }

  get isSettingPanelSupported() {
    return this.settings.get('apps.serviceCenter.settingsEnabled') || false;
  }

  rebootRequest() {
    const rebootEvent = new CustomEvent('reboot-needed', {
      detail: 'device-info-change',
    });

    window.dispatchEvent(rebootEvent);
  }

  requestInfo() {
    return new Promise((resolve, reject) => {
      MessageSender.send(new RequestDeviceInfoCommand(), (success, detail) => {
        if (success) {
          // utilize objectmodel to fill in some default values that are not available from client
          // XXX: detail.setting is a Map. Even the default defined in objectmodel is an object,
          // it is still able to tell if an attribute is defined.
          const deviceInfoWithDefaults = new DeviceInfo(detail);
          // make it as Map
          const newSettings = new Map(
            Object.entries(deviceInfoWithDefaults.settings)
          );
          const touchSettings = new Map(
            Object.entries(deviceInfoWithDefaults.touchSettings)
          );

          this.connection = detail.connection;
          this.settings = new Map([...newSettings, ...touchSettings]);
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  getTimeZoneOffset() {
    let t = new Date();
    let tzo = -t.getTimezoneOffset();
    if (tzo !== 0) {
      tzo = tzo / 60;
    }
    return tzo;
  }

  getTimeStamp() {
    let t = new Date();
    let tzo = -t.getTimezoneOffset();
    let dif = tzo >= 0 ? '+' : '-';
    return (
      t.getFullYear() +
      '-' +
      (t.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      t
        .getDate()
        .toString()
        .padStart(2, '0') +
      'T' +
      t
        .getHours()
        .toString()
        .padStart(2, '0') +
      ':' +
      t
        .getMinutes()
        .toString()
        .padStart(2, '0') +
      ':' +
      t
        .getSeconds()
        .toString()
        .padStart(2, '0') +
      dif +
      Math.abs(tzo / 60)
        .toString()
        .padStart(2, '0') +
      ':' +
      Math.abs(tzo % 60)
        .toString()
        .padStart(2, '0')
    );
  }
}
export const deviceUtils = new DeviceUtils();
