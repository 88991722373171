import React from 'react';
import PanelManager from '@/panel-manager';

import {
  formatBytes,
  generateCategoryName,
  generateLanguages,
} from '../pagePanelUtils';

const _ = navigator.mozL10n.get;

const SizeListItem = ({ size }) => (
  <kai-2lines-listitem
    text={size ? formatBytes(size) : '-'}
    subtext={_('size')}
    subtextontop
  />
);

const VersionListItem = ({ version }) => (
  <kai-2lines-listitem text={version} subtext={_('version')} subtextontop />
);

const showDeveloper = developer => () => {
  if (Object.keys(developer).length > 0)
    PanelManager.request({
      panel: 'developer',
      details: {
        developer,
      },
    });
};

const DeveloperListItem = ({ developer = {} }) => (
  <kai-2lines-listitem
    text={developer.name || '-'}
    subtext={_('developer')}
    onClick={showDeveloper(developer)}
    subtextontop
  />
);

const CategoryListItem = ({ categoryList }) => (
  <kai-2lines-listitem
    text={generateCategoryName(categoryList)}
    subtext={_('category')}
    subtextontop
  />
);

const LanguageListItem = ({ supportedLanguages }) => (
  <kai-2lines-listitem
    text={generateLanguages(supportedLanguages)}
    subtext={_('language')}
    subtextontop
  />
);

const DetailList = ({
  size,
  type,
  developer,
  categoryList,
  supportedLanguages,
  version,
}) => {
  const shouldDisplaySize = type !== 'bookmark' && type !== 'hosted';
  return (
    <div className="leftover-detail-wrapper">
      <DeveloperListItem developer={developer} />
      {shouldDisplaySize && <SizeListItem size={size} />}
      <VersionListItem version={version} />
      <CategoryListItem categoryList={categoryList} />
      <LanguageListItem supportedLanguages={supportedLanguages} />
    </div>
  );
};

export default DetailList;
