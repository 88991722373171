class NetworkHelper {
  constructor() {
    this.shouldFallback = this.fallback;
    this.addEventListeners();
  }

  get online() {
    if (this.shouldFallback) {
      return navigator.onLine;
    }
    const { type } = navigator.connection;
    return this.isOnlineNetworkType(type);
  }

  get fallback() {
    if ('connection' in navigator && navigator.connection.type) {
      return false;
    }
    return true;
  }

  isOnlineNetworkType(type) {
    return type === 'cellular' || type === 'wifi';
  }

  addEventListeners() {
    if (this.shouldFallback) {
      window.addEventListener('online', () => {
        this.onNetworkStatusChange(true);
      });
      window.addEventListener('offline', () => {
        this.onNetworkStatusChange(false);
      });
    } else {
      window.navigator.connection.ontypechange = event => {
        const online = this.isOnlineNetworkType(event.target.type);
        this.onNetworkStatusChange(online);
      };
    }
  }

  onNetworkStatusChange(online) {
    const event = new CustomEvent('networkstatuschange', {
      detail: {
        online,
      },
    });
    window.dispatchEvent(event);
  }
}

export default (NetworkHelper = new NetworkHelper());
