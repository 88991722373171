import React from 'react';
import CoreAppUpdateHelper from '@/helper/core-app-update-helper';
import ACTIONS from '../constants';

const _ = navigator.mozL10n.get;

const handleClick = (action, application, isCore) => () => {
  switch (action) {
    case ACTIONS.OPEN: {
      application.launch(application.manifestURL);
      break;
    }
    case ACTIONS.STOP: {
      application.cancelDownload();
      break;
    }
    case ACTIONS.UPDATE:
    case ACTIONS.UPDATEANDRESTART: {
      if (isCore) {
        const manifestURLs = [application.manifestURL];
        CoreAppUpdateHelper.upsert(manifestURLs);
        // TODO: need to handle after update is done
      } else {
        application.update();
      }
      break;
    }
    default:
      break;
  }
};

const ActionRow = ({ action, application, isCore }) => (
  <div className="action-row">
    <div className="left" />
    <div className="right">
      <kai-pillbutton
        level="primary"
        action={action}
        text={_(action)}
        onClick={handleClick(action, application, isCore)}
      />
    </div>
  </div>
);

export default ActionRow;
