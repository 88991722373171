import Account from '../account';

const _ = navigator.mozL10n.get;

const UPDATE_ITEM = {
  icon: 'update',
  title: 'update',
  id: 'update',
  panel: {
    name: 'update',
  },
};

const SETTINGS_ITEM = {
  icon: 'settings',
  title: 'settings',
  id: 'settings',
  panel: {
    name: 'settings',
  },
};

const DOWNLOAD_HISTORY_ITEM = {
  icon: 'file-download-01',
  title: 'download-history',
  id: 'download-history',
  panel: {
    name: 'downloadHistory',
  },
};

const TERMS_AND_POLICY_ITEM = {
  icon: 'privacy',
  title: 'terms-and-policy',
  id: 'terms-and-policy',
  panel: {
    name: 'more',
    details: {
      page: 'terms-and-policy',
    },
  },
};

const HELP_ITEM = {
  icon: 'information',
  title: 'help',
  id: 'help',
  panel: {
    name: 'more',
    details: {
      page: 'help',
    },
  },
};

class MorePanelHelper {
  getDetail(page) {
    if (page === 'more') {
      // Show login status and purchased app option if account app is enabled
      const items = [
        UPDATE_ITEM,
        SETTINGS_ITEM,
        DOWNLOAD_HISTORY_ITEM,
        TERMS_AND_POLICY_ITEM,
        HELP_ITEM,
      ];

      if (Account.version) {
        items.splice(0, 0, this.getAccountItem());
      }

      return {
        title: _('more'),
        type: 'large',
        items,
      };
    }
    if (page === 'terms-and-policy') {
      return {
        title: _('terms-and-policy'),
        items: [
          {
            title: 'terms-and-conditions',
            id: 'terms',
            url: 'https://www.kaiostech.com/kaistore-terms-of-service/',
          },
          {
            title: 'privacy-policy',
            id: 'policy',
            url: 'https://www.kaiostech.com/privacy-policy/',
          },
        ],
      };
    }
    if (page === 'help') {
      return {
        title: _('help'),
        items: [
          {
            title: 'customer-support',
            id: 'customer-support',
            url: 'https://support.kaiostech.com/support/tickets/new',
          },
        ],
      };
    }
    return {};
  }

  getAccountItem() {
    const accountItem = {
      icon: 'contacts',
      title: 'kaios-account',
      id: 'account',
      callback: () => {
        Account.openAccount('fxa');
      },
    };
    const accountId = Account.id;
    const isLoggedIn = accountId !== null;
    if (isLoggedIn === false) {
      accountItem.subtitlePlain = _('not-signed-in');
      accountItem.callback = () => {
        Account.openAccount('fxa');
      };
    } else {
      accountItem.subtitlePlain = accountId;
      accountItem.callback = Account.openAccount;
    }
    return accountItem;
  }
}

export default new MorePanelHelper();
