import React from 'react';

export default ({ type }) => {
  if (type !== 'openpwa') {
    return null;
  }
  return (
    <div className="unverified-warning">
      <div className="container">
        <div className="icon" />
        <div className="text" data-l10n-id="unverified-warning" />
      </div>
    </div>
  );
};
