import React from 'react';
import PanelManager from '@/panel-manager';

const showScreenShot = (screenshots, index = 0) => {
  const urls = Object.values(screenshots);
  PanelManager.request({
    panel: 'screenshot',
    details: {
      images: urls,
      index,
    },
  });
};

export default ({ screenshots }) => {
  if (screenshots && Object.values(screenshots).length === 0) {
    return null;
  }
  return (
    <div className="screenshots-section">
      {Object.values(screenshots).map((imageURL, index) => (
        <div
          className="screenshot"
          onClick={() => {
            showScreenShot(screenshots, index);
          }}
        >
          <img src={imageURL} />
        </div>
      ))}
    </div>
  );
};
