import React, { Component } from 'react';
import {
  validSettingData,
  validSettingKey,
} from 'kaistore-post-messenger/lib/constants';
import { MessageSender } from 'web-message-helper';
import {
  RequestSettingCommand,
  UpdateSettingCommand,
} from 'kaistore-post-messenger/src/commands';
import { Setting } from 'kaistore-post-messenger/src/models';

import '../../scss/panel/SettingsPanel.scss';

const _ = navigator.mozL10n.get;
class SettingsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [validSettingKey.AUTO_DOWNLOAD]: null,
    };

    this.settings = [
      {
        key: validSettingKey.AUTO_DOWNLOAD,
        value: validSettingData[validSettingKey.AUTO_DOWNLOAD].default, // default value
        options: validSettingData[validSettingKey.AUTO_DOWNLOAD].values,
        titleID: 'auto-update',
      },
    ];

    this.getSettings();
  }

  getSettings() {
    for (let i = 0; i < this.settings.length; i++) {
      const { key } = this.settings[i];
      const command = new RequestSettingCommand({ detail: { key } });
      MessageSender.send(command, (success, result) => {
        const defaultValue = validSettingData[key].default;
        if (!success) {
          this.saveSetting(key, defaultValue);
        }
        this.setState({ [key]: success ? result.value : defaultValue });
      });
    }
  }

  saveSetting(key, value) {
    const setting = new Setting({ key, value });
    MessageSender.send(new UpdateSettingCommand({ detail: setting }));
  }

  render() {
    const { settings } = this;

    return (
      <div className="settings-panel">
        <kai-header primarytitle={_('settings')} />
        {settings.map(item => (
          <div key={item.key} className="settings-item">
            <kai-2lines-listitem
              text={_(item.titleID)}
              subtext={
                this.state[item.key] ? _(this.state[item.key]) : undefined
              }
              onClick={() => {
                item.ref.focus();
              }}
            >
              <i slot="listitem-right-slot" data-icon="forward" />
            </kai-2lines-listitem>
            <select
              ref={element => {
                item.ref = element;
              }}
              onChange={event => {
                const newValue = event.target.value;
                this.setState({ [item.key]: newValue });
                this.saveSetting(item.key, newValue);
              }}
            >
              {item.options.map(option => (
                <option
                  key={`${item.key}-${option}`}
                  value={option}
                  selected={option === this.state[item.key]}
                >
                  {_(option)}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
    );
  }
}

export default SettingsPanel;
