import React, { Component } from 'react';

import AppStore from '@/app-store';
import { UpdatePanelUI, ErrorPanel } from './component';
import 'scss/panel/UpdatePanel.scss';

class UpdatePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatableApps: AppStore.updatableApplications,
    };
    window.addEventListener('appstore:change', this.handleStoreChange);
  }

  handleStoreChange = () => {
    this.setState({ updatableApps: AppStore.updatableApplications });
  };

  render() {
    const { updatableApps } = this.state;
    return updatableApps.length > 0 ? (
      <UpdatePanelUI
        number={updatableApps.length}
        updatableApps={updatableApps}
      />
    ) : (
      <ErrorPanel title="Update" status="no-update" />
    );
  }
}

export default UpdatePanel;
