import React from 'react';
import PanelManager from '@/panel-manager';

const _ = navigator.mozL10n.get;

// TODO: FIXME: remove screen shots panel
const showScreenShot = screenshots => {
  const urls = Object.values(screenshots);
  PanelManager.request({
    panel: 'screenshot',
    details: {
      images: urls,
    },
  });
};

const ScreenshotsButton = ({ screenshots }) => (
  <div className="screenshot-wrapper">
    <div className="btn" onClick={() => showScreenShot(screenshots)}>
      <div className="icon-wrapper">
        <i data-icon="file-photo" />
      </div>
      <h4 className="title">{_('screenshots')}</h4>
    </div>
  </div>
);

export default ScreenshotsButton;
