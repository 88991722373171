import { MessageSender } from 'web-message-helper';
import {
  RemoteUIReadyCommand,
  ShowNotificationCommand,
} from 'kaistore-post-messenger/src/commands';
import { Notification } from 'kaistore-post-messenger/src/models';

class ViewsHelper {
  /**
   * Initializes variables.
   */
  constructor() {
    this.UIReadyCommandSent = false;
    this.UIHasFocus = false;
    // For KaiOS 3.0 only, recording the latest focus element, and focus it
    // when "UIHasFocus" change to true.
    this.focusElement = null;
  }

  /**
   * Notify client about remote UI is ready.
   * @type {Function}
   * @public
   */
  notifyUIReady(isInlineActivity = false) {
    if (this.UIReadyCommandSent) {
      return Promise.resolve({ hasSentBefore: true });
    }
    return new Promise(resolve => {
      const timestamp = new Date().getTime();
      MessageSender.send(
        new RemoteUIReadyCommand({
          detail: {
            timestamp,
            isInlineActivity,
          },
        }),
        () => {
          this.UIReadyCommandSent = true;
          resolve({ hasSentBefore: false });
        }
      );
    });
  }
}

export default (ViewsHelper = new ViewsHelper());

/**
 * Checks if the element is overflown.
 * @method isOverflown
 * @param {DOM} element - HTML DOM element
 * @return {Boolean}
 */
export const isOverflown = element => {
  if (!element) {
    return false;
  }
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
};

/**
 * TODO: Could extract all notification related functions to new helper if
 * we need to show varied notifications in the future. But for now we only got
 * one so put it here first.
 */
export const showNotification = (titleL10nId, options) => {
  const title = navigator.mozL10n.get(titleL10nId);
  const command = new ShowNotificationCommand({
    detail: new Notification({
      title,
      options,
    }),
  });
  MessageSender.send(command);
};

/**
 * scrollIntoView() only works vertically so we need to calculate by ourselves
 * for scroll to horizontal center.
 */
export const scrollToHorizontalCenter = (
  targetDOM,
  containerDOM,
  smoothScrolling = false
) => {
  const { scrollLeft, offsetWidth } = containerDOM;
  const { left, width } = targetDOM.getBoundingClientRect();
  containerDOM.scrollTo({
    left: scrollLeft + left - offsetWidth / 2 + width / 2,
    behavior: smoothScrolling ? 'smooth' : 'auto',
  });
};
