import React from 'react';
import { ACTIONS } from '../constant';

const ProgressBar = ({ action, progressState }) =>
  action === ACTIONS.STOP ? (
    <div className="progress-wrapper">
      <kai-progress
        type={progressState === null ? 'indeterminate' : 'determined'}
        percent={progressState === null ? 0 : progressState}
      />
    </div>
  ) : (
    <div className="divider" />
  );

export default ProgressBar;
