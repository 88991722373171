import React from 'react';

const _ = navigator.mozL10n.get;

const Description = ({ description, reboot }) => (
  <>
    {reboot && <p className="restart-msg">{_('update-restart-message-2')}</p>}
    <div className="desc-wrapper">{description}</div>
  </>
);

export default Description;
