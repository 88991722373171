import BaseEmitter from 'base-emitter';

import { MessageSender } from 'web-message-helper';
import { RequestExitCommand } from 'kaistore-post-messenger/src/commands';

import analyticsHelper from '@/helper/analytics-helper';
import AppStore from '@/app-store';
import {
  GAMES_CATEGORY_CODE,
  ALL_APPS_EXCLUDED_OPEN_PWA_CODE,
  OPEN_PWA_CODE,
  SECTION_NAME,
} from '@/constants';

const _ = navigator.mozL10n.get;

class PanelManager extends BaseEmitter {
  constructor() {
    super();
    this.panelPool = new Map();
    this.init();
  }

  init() {
    this._currentPanel = {};
    this.panelDetails = {};
    this._history = {};
    // first section could be 'explore' or open 'page' panel directly by MozActivity, which does not belong to any section
    this._currentSection = null;
  }

  set currentPanel(panel) {
    this._currentPanel = panel;
    this.emit('change', {
      panel: this._currentPanel,
      details: this.panelDetails,
    });
  }

  set currentSection(section) {
    this._currentSection = section;
    this.emit('sectionChanged');
  }

  get currentPanel() {
    return this._currentPanel;
  }

  get currentSection() {
    return this._currentSection;
  }

  get history() {
    return this._history;
  }

  get isAppDetailPage() {
    return this._currentPanel === 'page';
  }

  isAtAppDetailPage(id) {
    const details = this.panelDetails;
    return this.isAppDetailPage && details.id === id;
  }

  register(panels) {
    panels.forEach(panel => {
      this.panelPool.set(panel);
    });
  }

  request(config, addHistory = true) {
    // clean up previous data.
    this.panelDetails = {};
    if (!this.panelPool.has(config.panel)) {
      this.emit('change', { panel: 'notFound' });
      return;
    }

    if (config.details) {
      this.panelDetails = config.details;
    }

    this.currentPanel = config.panel;
    this.handleLog(config);
    if (addHistory) {
      this._addHistory(config);
      analyticsHelper.saveBrowsedHistory(config);
    }
  }

  handleLog(config) {
    const { panel, details } = config;

    switch (panel) {
      case 'page': {
        const appId = details.id;
        const application = AppStore.findAppById(appId);
        const { id, version } = application;
        const appOrder = details.appOrder
          ? parseInt(details.appOrder, 10)
          : null;

        if (!application) {
          console.error(`[panel-manager]Cannot found the App by id: ${appId}`);
          return;
        }
        analyticsHelper.postAppViewInit({
          event_name: 'store_app_view_init',
          app_id: id,
          app_version: version,
          app_order: appOrder,
        });
        break;
      }
      default:
        break;
    }
  }

  back() {
    if (
      this._history[this._currentSection] &&
      this._history[this._currentSection].length > 1
    ) {
      const history = this._history[this._currentSection];
      const next = history.splice(history.length - 2)[0];
      this.request(next);
    } else {
      MessageSender.send(new RequestExitCommand());
    }
  }

  resetHistoryBySection(section) {
    if (this._history[section] && this._history[section].length > 0) {
      const next = this._history[section][0];
      this._clearHistoryBySection(section);
      this.request(next);
    }
  }

  _addHistory(config = {}) {
    if (this._currentSection && config.panel) {
      if (this._history[this._currentSection]) {
        this._history[this._currentSection].push(config);
      } else {
        this._history[this._currentSection] = [config];
      }
    }
  }

  _clearHistoryBySection(section) {
    if (this._history[section]) {
      this._history[section] = [];
    }
  }

  resetHistory() {
    this._history = {};
  }

  requestAppsPanel(isFirstPageReady = false) {
    this.currentSection = SECTION_NAME.APPS;
    this.request({
      panel: 'applist',
      details: {
        category: {
          code: ALL_APPS_EXCLUDED_OPEN_PWA_CODE,
          displayName: _('apps'),
          largeHeader: true,
          isFirstPageReady,
        },
      },
    });
  }

  requestGamesPanel() {
    this.request({
      panel: 'applist',
      details: {
        category: {
          code: GAMES_CATEGORY_CODE,
          displayName: _('games'),
          largeHeader: true,
        },
      },
    });
  }

  requestDiscoverPanel() {
    this.request({
      panel: 'applist',
      details: {
        category: {
          code: OPEN_PWA_CODE,
          displayName: _('discover'),
          largeHeader: true,
        },
      },
    });
  }

  appendAppsPanelHistory() {
    this.currentSection = SECTION_NAME.APPS;
    this._addHistory({
      panel: 'applist',
      details: {
        category: {
          code: ALL_APPS_EXCLUDED_OPEN_PWA_CODE,
          displayName: _('apps'),
          largeHeader: true,
        },
      },
    });
  }

  updateHistoryDetail(details) {
    const currentHistorySection = this.history[this.currentSection];
    const currentHistoryRecord =
      currentHistorySection[currentHistorySection.length - 1];
    currentHistoryRecord.details = {
      ...currentHistoryRecord.details,
      ...details,
    };
  }
}

export default new PanelManager();
