import React, { PureComponent } from 'react';
import PanelManager from '@/panel-manager';

class SearchBar extends PureComponent {
  constructor(props) {
    super(props);
    this.searchBarRef = React.createRef();
  }

  componentDidMount() {
    const searchBar = this.searchBarRef.current;
    searchBar.addEventListener('cancel', this.handleCancel);
    searchBar.addEventListener('input', this.handleInput);
    searchBar.addEventListener('clear', this.handleClear);
  }

  componentDidUpdate(prevProps) {
    const searchBar = this.searchBarRef.current;
    const { activate, searchIn } = this.props;
    const { activate: prevActivate, searchIn: prevSearchIn } = prevProps;
    // handle focus when searchIn change searchPanel is activated
    if (
      (activate && searchIn !== prevSearchIn) ||
      (activate && activate !== prevActivate)
    ) {
      searchBar.focus();
    }
  }

  componentWillUnmount() {
    const searchBar = this.searchBarRef.current;
    searchBar.removeEventListener('input', this.handleInput);
    searchBar.removeEventListener('clear', this.handleClear);
    searchBar.removeEventListener('cancel', this.handleCancel);
  }

  handleInput = e => {
    const {
      detail: { value },
    } = e;
    const { handleQuery } = this.props;
    handleQuery(value);
  };

  handleClear = () => {
    const { handleQuery } = this.props;
    handleQuery('');
  };

  handleCancel = () => {
    const { query, handleQuery } = this.props;
    if (query !== '') handleQuery('');
    PanelManager.back();
  };

  render() {
    const { query } = this.props;
    return (
      <kai-searchbar
        value={query}
        withcancel
        ref={this.searchBarRef}
        isheader
      />
    );
  }
}

export default SearchBar;
