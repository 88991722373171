import AppListHelper from '@/helper/applist-helper';
import SearchHelper from '@/helper/search-helper';
import MozAppHelper from '@/helper/mozapp-helper';
import GraphQLHelper from '@/helper/graphql-helper';
import PanelManager from '../panel-manager';

import Application from './application';
import { hasGameCategory, formatRemoteManifestURL } from './app-store-utils';

import {
  DEFAULT_PAGE_SIZE,
  INIT_PAGE_NUMBER,
  CACHE_TIME_LIMIT,
  ALL_APPS_KEY,
  STATUS,
} from './constants';
import {
  SEARCH_CRITERIA,
  GAMES_CATEGORY_CODE,
  ALL_APPS_EXCLUDED_OPEN_PWA_CODE,
  EXPLORE_CATEGORY_CODE,
  ASYNCSTORAGE_KEY,
} from '@/constants';

import { RequestBookmarksCommand } from 'kaistore-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';
import { OPEN_PWA_CODE } from '../constants';

class AppStore {
  constructor() {
    this.init();
  }

  init() {
    // applicationList: {manifestURL: {mozApp, remoteApp, status}}
    this.applicationList = new Map();
    this.isRemoteInfoSet = false;
    /*
     * categoryPageInfo:
     *  key: cateCode
     *  value: {
     *    pageNumber,
     *    (pageSize): use DEFAULT_PAGE_SIZE; won't store in the Map;
     *    lastUpdateTime,
     *    isLastPage,
     *  }
     * FIXME: special case: if appStore get all apps already
     *  key: 'ALL'
     *  value: {
     *  isLastPage, (all apps info have been downloaded if true)
     *  lastUpdateTime
     * }
     */
    this.categoryPageInfo = new Map();
    this.bookmarksMap = new Map();
    this.isBookmarkDBSupported = false;
  }

  async initAsync() {
    // need to wait for window.deviceInfos and window.IMEI ready, which was in boot() of app.js
    this.appListHelper = new AppListHelper();
    this.graphQLHelper = new GraphQLHelper();
    await this.getBookmarkDB();

    // get purchasedAppIds from server and add appIds to the Set
    const purchasedAppIds = await this.appListHelper.getPurchasedAppIds();
    this.purchasedAppIds = new Set(purchasedAppIds);
  }

  resetStore() {
    this.init();
  }

  async start(exploreCateCode) {
    await this.handleInitMozApps();
    await this.handleAppendAppsByCategoryCode(exploreCateCode);
    this.isRemoteInfoSet = true;
  }

  async startWithAppsSection() {
    await this.handleInitMozApps();
    await this.handleAppendAppsByCategoryCode(ALL_APPS_EXCLUDED_OPEN_PWA_CODE);
    this.isRemoteInfoSet = true;
  }

  // FIXME: append all apps for now; refactor this function to handle append by name or url
  async startWithAllApps() {
    await this.handleInitMozApps();
    const isLastPage = await this.appendAppsByCategoryCode();
    this.categoryPageInfo.set(ALL_APPS_KEY, {
      isLastPage,
      lastUpdateTime: new Date().getTime(),
    });
    this.isRemoteInfoSet = true;
  }

  async handleInitMozApps() {
    // installed or downloading mozApp
    const installedMozApps = await MozAppHelper.getInstalledMozApps();
    Object.keys(installedMozApps).forEach(manifestURL =>
      this.initMozApp(manifestURL.trim(), installedMozApps[manifestURL])
    );
  }

  initMozApp(manifestURL, mozApp) {
    const application =
      this.findAppByManifest(manifestURL) || new Application(manifestURL);
    application.mozApp = mozApp;
    application.updateStatus({ [STATUS.INSTALLED]: true }, false);

    this.applicationList.set(application.manifestURL, application);
  }

  async handleUpdateMozApps(manifestURLs) {
    if (!manifestURLs) return;
    const updatedMozApps = await MozAppHelper.getMozApps(manifestURLs);
    updatedMozApps.forEach(mozApp => this.updateMozApp(mozApp));
  }

  updateMozApp(mozApp) {
    const manifestURL = mozApp.manifestURL.trim();
    const application =
      this.findAppByManifest(manifestURL) || new Application(manifestURL);
    application.updateMozApp(mozApp);
    this.applicationList.set(application.manifestURL, application);
    this.publish('appstore:change');
  }

  initSearchApp(searchApp) {
    const { manifest_url: manifest } = searchApp;
    // since mozApp and remoteApp's manifestURL will be encodeURI-ed, encodeURI searchApp as well
    const manifestURL = encodeURI(manifest.trim());
    const application =
      this.findAppByManifest(manifestURL) || new Application(manifestURL);
    application.searchApp = searchApp;
    application.updatePurchased(this.purchasedAppIds.has(application.id));
    if (application.isBookmark && this.bookmarksMap.has(application.info.url)) {
      application.updatePinStatus(true);
    }
    this.applicationList.set(application.manifestURL, application);
  }

  async handleSearch(query, searchIn = PanelManager.currentSection) {
    const searchApps = await SearchHelper.searchApps(query);

    searchApps.forEach(searchApp => this.initSearchApp(searchApp));

    if (searchIn === SEARCH_CRITERIA.GAMES) {
      return searchApps.filter(app => hasGameCategory(app));
    }
    if (searchIn === SEARCH_CRITERIA.APPS) {
      return searchApps.filter(app => !hasGameCategory(app));
    }
    if (searchIn === SEARCH_CRITERIA.DISCOVER) {
      return searchApps.filter(app => app.info && app.info.type === 'openpwa');
    }
    if (searchIn === SEARCH_CRITERIA.DEVELOPER) {
      return searchApps.filter(
        app => app.developer.toLowerCase() === query.toLowerCase()
      );
    }
    await this.appListHelper.fetchPaidAppInfo(searchApps);
    return searchApps;
  }

  getCategoryPageInfo(cateCode) {
    // If got all apps already, use the categoryPageInfo of ALL_APPS
    if (this.categoryPageInfo.has(ALL_APPS_KEY)) {
      return this.categoryPageInfo.get(ALL_APPS_KEY);
    }
    const hasPageInfo = this.categoryPageInfo.has(cateCode);
    if (!hasPageInfo) {
      this.categoryPageInfo.set(cateCode, {
        pageNumber: INIT_PAGE_NUMBER,
        isLastPage: false,
        lastUpdateTime: null,
      });
    }
    const pageInfo = {
      ...this.categoryPageInfo.get(cateCode),
      pageSize: DEFAULT_PAGE_SIZE,
    };
    return cateCode === EXPLORE_CATEGORY_CODE
      ? { ...pageInfo, isExplore: true }
      : pageInfo;
  }

  async handleAppendAppsByCategoryCode(cateCode) {
    const pageInfo = this.getCategoryPageInfo(cateCode);
    const now = new Date().getTime();
    if (
      // first time entering the category or has not reached the last page
      !pageInfo.isLastPage ||
      // XXX: cache mechanism
      now - pageInfo.lastUpdateTime > CACHE_TIME_LIMIT
    ) {
      const isLastPage = await this.appendAppsByCategoryCode(
        cateCode,
        pageInfo
      );
      const updatedPageInfo = {
        isLastPage,
        pageNumber: isLastPage ? pageInfo.pageNumber : pageInfo.pageNumber + 1,
        lastUpdateTime: now,
      };
      this.categoryPageInfo.set(cateCode, updatedPageInfo);
    }
  }

  async appendAppsByCategoryCode(cateCode, pageInfo = null) {
    const excludedCategory =
      cateCode === ALL_APPS_EXCLUDED_OPEN_PWA_CODE ? OPEN_PWA_CODE : null;
    // fetchAppList will get all apps if cateCode or pageInfo is not available
    const {
      apps,
      last_page: isLastPage,
    } = await this.appListHelper.fetchAppList(
      cateCode === ALL_APPS_EXCLUDED_OPEN_PWA_CODE ? null : cateCode,
      pageInfo,
      excludedCategory
    );
    if (apps && apps.length > 0) {
      apps.forEach(app => this.initRemoteApp(app));
      // Publish 'appstore:change' event just once to prevent sending numerous events after each application.syncInstalledApp
      this.publish('appstore:change');
    }
    return isLastPage;
  }

  initRemoteApp(remoteApp, bookmarksInDB) {
    const { manifest_url: manifest } = remoteApp;
    // since mozApp's manifestURL will be encodeURI-ed, encodeURI remoteApp as well
    const manifestURL = encodeURI(manifest.trim());
    const application =
      this.findAppByManifest(manifestURL) || new Application(manifestURL);
    application.initRemoteApp(remoteApp);
    application.updatePurchased(this.purchasedAppIds.has(application.id));
    if (application.isBookmark && this.bookmarksMap.has(application.info.url)) {
      application.updatePinStatus(true);
    }
    this.applicationList.set(application.manifestURL, application);
  }

  async getBookmarkDB() {
    return new Promise(resolve => {
      MessageSender.send(new RequestBookmarksCommand(), (success, detail) => {
        if (success) {
          this.bookmarksMap = new Map(Object.entries(detail));
          this.isBookmarkDBSupported = true;
        } else {
          this.isBookmarkDBSupported = false;
        }
        resolve();
      });
    });
  }

  get allApplications() {
    return [...this.applicationList].map(entry => entry[1]);
  }

  get bookmarks() {
    return this.allApplications.filter(application => application.isBookmark);
  }

  get updatableApplications() {
    return this.allApplications.filter(({ status }) => status.updatable);
  }

  // used in MorePanel and PurchasedPanel
  get purchasedApps() {
    return this.allApplications.filter(({ status }) => status.purchased);
  }

  // RemoteApps listed on apps panel
  get allListedRemoteApps() {
    return this.allApplications.filter(
      ({ remoteApp }) => remoteApp && Object.keys(remoteApp).length > 0
    );
  }

  findAppByManifest(manifestURL) {
    return this.applicationList.get(manifestURL);
  }

  // used in app.js to handle activity, need to get name from application when remoteApp is not always available
  findAppByName(name) {
    return this.allApplications.find(({ info }) => info && info.name === name);
  }

  findAppById(id) {
    return this.allApplications.find(({ id: appId }) => appId === id);
  }

  getAppsByCategoryCode(code) {
    if (code === 'all') {
      return this.allListedRemoteApps;
    }
    if (code === ALL_APPS_EXCLUDED_OPEN_PWA_CODE) {
      return this.allListedRemoteApps.filter(
        ({ info }) =>
          info &&
          !(info.categoryList && info.categoryList.includes(OPEN_PWA_CODE))
      );
    }
    if (code === OPEN_PWA_CODE) {
      return this.allListedRemoteApps.filter(
        ({ info }) => info && info.type === 'openpwa'
      );
    }
    return this.allListedRemoteApps.filter(
      ({ info }) =>
        info && info.categoryList && info.categoryList.includes(code)
    );
  }

  findBookmarkByUrl(url) {
    return this.bookmarks.find(bookmark => bookmark.info.url === url);
  }

  handleInstall(mozApp) {
    const application = this.findAppByManifest(mozApp.manifestURL);
    if (application) application.handleInstall(mozApp);
    else console.error('[app-store] handleInstall application not found');
  }

  publish(eventName, detail) {
    const event = detail
      ? new CustomEvent(eventName, {
          detail,
        })
      : new CustomEvent(eventName);
    window.dispatchEvent(event);
  }

  fetchGraphQL(graphQLQuery, shouldReturnYMAL = false) {
    return this.graphQLHelper
      .fetchApp(graphQLQuery, shouldReturnYMAL)
      .then(app => {
        if (!app) throw new Error('App not found through graphQL');
        const application = this.handleFormatGraphQLApp(app);
        return application;
      });
  }

  // if graphQLApp contains YMAL apps, create/update Application for them first
  handleFormatGraphQLApp(graphQLApp) {
    const ymalApps = graphQLApp.ymal;
    if (ymalApps) {
      ymalApps.forEach(ymalApp => this.formatGraphQLApp(ymalApp));
    }
    const application = this.formatGraphQLApp(graphQLApp);
    return application;
  }

  formatGraphQLApp(graphQLApp) {
    if (!graphQLApp.manifest_url) {
      console.warn(
        `Malformed app ${graphQLApp.name} without manifest_url. Skip.`
      );
      return;
    }
    const manifestURL = formatRemoteManifestURL(graphQLApp.manifest_url);
    const existedApplication = this.findAppByManifest(manifestURL);
    const application = existedApplication || new Application(manifestURL);

    /**
     * https://bugzilla.kaiostech.com/show_bug.cgi?id=109053#c9
     * only show first 3 uninstalled ymal
     */
    if (graphQLApp.ymal) {
      const selectedYmalApps = graphQLApp.ymal
        .filter(ymalApp => {
          const manifest = ymalApp.manifest_url;
          const ymalApplication = this.findAppByManifest(manifest);
          return ymalApplication && !ymalApplication.isInstalled;
        })
        .slice(0, 3);
      graphQLApp.ymal = selectedYmalApps;
    }

    application.graphQLApp = graphQLApp;
    this.applicationList.set(manifestURL, application);
    return application;
  }

  getDownloadAppRecord() {
    return new Promise(resolve => {
      asyncStorage.getItem(ASYNCSTORAGE_KEY.DOWNLOADED_APPS, result => {
        const record = result || new Map();
        resolve(record);
      });
    });
  }

  addDownloadAppRecord(app) {
    return new Promise(resolve => {
      this.getDownloadAppRecord().then(result => {
        const record = !!result && !!result.set ? result : new Map();
        const { manifestURL, info, localized, id } = app;
        const downloadedDate = Date.now() * 1000000; // * 1000000 to match "created_date" from paymentHelper.getAllPurchased()
        const data = {
          id,
          manifestURL,
          downloadedDate,
          info,
          localized,
        };
        record.set(id, data);
        asyncStorage.setItem(ASYNCSTORAGE_KEY.DOWNLOADED_APPS, record, () => {
          this.publish('appstore:change');
          resolve();
        });
      });
    });
  }

  getPurchasedAppsInfo() {
    if (this.purchasedApps.length === 0) {
      return new Map();
    }
    return new Map(
      this.purchasedApps.map(app => [
        app.id,
        {
          manifestURL: app.manifestURL,
          downloadedDate: app.purchasedDate,
          ...app.info,
        },
      ])
    );
  }

  async downloadedOrPurchasedApps() {
    const sortDownloadRecordByDateTime = (record = []) => {
      record.sort((a, b) => b.downloadedDate - a.downloadedDate);
      return record;
    };
    const downloadRecord = await this.getDownloadAppRecord();

    if (downloadRecord && this.purchasedApps) {
      const purchasedRecord = this.getPurchasedAppsInfo();

      // remove duplicate.
      downloadRecord.forEach((app, id) => {
        if (purchasedRecord.has(id)) {
          purchasedRecord.delete(id);
        }
      });

      return sortDownloadRecordByDateTime([
        ...downloadRecord.values(),
        ...purchasedRecord.values(),
      ]);
    }

    if (this.purchasedApps) {
      return sortDownloadRecordByDateTime([
        ...this.getPurchasedAppsInfo().values(),
      ]);
    }

    if (downloadRecord) {
      return sortDownloadRecordByDateTime([...downloadRecord.values()]);
    }

    return [];
  }
}

export default new AppStore();
