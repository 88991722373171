import React from 'react';

const getL10nId = searchIn => {
  return `search-${searchIn}`;
};

const Empty = ({ searchIn }) => (
  <div>
    <div className="search-img-wrapper">
      <div className="search-img" data-search={searchIn} />
    </div>
    <div className="search-msg-wrapper">
      <div className="content" data-l10n-id={getL10nId(searchIn)} />
    </div>
  </div>
);

export default Empty;
