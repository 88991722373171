import React, { Component } from 'react';

import AppStore from '../app-store';
import AppItem from '../component/AppItem';

const _ = navigator.mozL10n.get;

class DownloadHistoryPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadedOrPurchasedApps: [],
    };

    window.addEventListener('appstore:change', this.handleStoreChange);
  }

  handleStoreChange = async () => {
    const downloadedOrPurchasedApps = await AppStore.downloadedOrPurchasedApps();
    if (
      JSON.stringify(this.state.downloadedOrPurchasedApps) !==
      JSON.stringify(downloadedOrPurchasedApps)
    ) {
      this.setState({ downloadedOrPurchasedApps });
    }
  };

  render() {
    const { downloadedOrPurchasedApps } = this.state;

    return (
      <div className="purchased-panel">
        <kai-header primarytitle={_('download-history')} />
        {downloadedOrPurchasedApps.map(app => (
          <AppItem key={app.id} app={app} />
        ))}
      </div>
    );
  }
}

export default DownloadHistoryPanel;
