// TODO: This panel is temporarily used for 'Games', so lots of statements are tailored for the special cases. Remove them when GamePanel is done
import React, { PureComponent } from 'react';
import throttle from 'lodash.throttle';
import AppStore from '@/app-store';
import PanelManager from '@/panel-manager';
import SearchButton from '@/component/SearchButton';

import AppItem from '@/component/AppItem';

import { OPEN_PWA_CODE } from '@/constants';

import '../../scss/panel/AppListPanel.scss';

const PAGE_SIZE = 20;
// 70 px according to spec
const APP_ITEM_MAX_HEIGHT = (70 * window.innerWidth) / 360;
const NUMBER_OF_ROW_TO_LOAD = 12;
class AppListPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.isFetching = false;
    this.state = {
      apps: [],
    };
    window.addEventListener('appstore:change', this.updateAppsStatus);
  }

  async componentDidMount() {
    const { isFirstPageReady } = this.props.category;
    await this.getApps(isFirstPageReady);
  }

  async componentDidUpdate(prevProps) {
    const {
      category: { code: prevCode },
    } = prevProps;
    const {
      category: { code },
    } = this.props;

    if (prevCode !== code) {
      if (typeof PanelManager.panelDetails.scrollTop !== 'undefined') {
        requestAnimationFrame(() => {
          this.element.scrollTop = PanelManager.panelDetails.scrollTop;
        });
      }
      await this.resetApps();
    }
  }

  getApps = async (skipRequest = false) => {
    const {
      category: { code },
    } = this.props;
    // XXX: ugly isFetching flag
    this.isFetching = true;
    if (!skipRequest) {
      await AppStore.handleAppendAppsByCategoryCode(code);
    }
    this.isFetching = false;
    this.setState({ apps: AppStore.getAppsByCategoryCode(code) });
  };

  handleScroll = () => {
    const {
      category: { code },
    } = this.props;
    const { isLastPage } = AppStore.getCategoryPageInfo(code);
    if (
      this.element.scrollTopMax - this.element.scrollTop <=
        NUMBER_OF_ROW_TO_LOAD * APP_ITEM_MAX_HEIGHT &&
      !isLastPage &&
      !this.isFetching
    ) {
      this.getApps();
    }
    PanelManager.updateHistoryDetail({ scrollTop: this.element.scrollTop });
  };

  updateAppsStatus = () => {
    const {
      category: { code },
    } = this.props;
    this.setState({ apps: AppStore.getAppsByCategoryCode(code) });
  };

  async resetApps() {
    const {
      category: { code },
    } = this.props;
    const apps = AppStore.getAppsByCategoryCode(code);
    const { isLastPage } = AppStore.getCategoryPageInfo(code);

    if (apps.length < PAGE_SIZE && !isLastPage) {
      this.setState({ apps: [] });
      await AppStore.handleAppendAppsByCategoryCode(code);
    }
    this.setState({ apps: AppStore.getAppsByCategoryCode(code) });
  }

  render() {
    const { apps } = this.state;
    const { largeHeader, category } = this.props;
    return (
      <div
        className="Appsview"
        tabIndex="1"
        ref={target => {
          this.element = target;
        }}
        onScroll={throttle(this.handleScroll, 500)}
      >
        {largeHeader ? (
          <kai-header
            primarytitle={category.displayName}
            type={largeHeader ? 'large' : ''}
          >
            <SearchButton />
          </kai-header>
        ) : (
          <kai-header primarytitle={category.displayName} />
        )}

        {category.code === OPEN_PWA_CODE && (
          <div className="desc" data-l10n-id="discover-subtitle" />
        )}

        {apps.map((application, index) => (
          <AppItem
            key={application.id}
            app={application}
            order={index}
            isDownloading={application.status.downloading}
          />
        ))}
      </div>
    );
  }
}

export default AppListPanel;
