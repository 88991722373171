import React from 'react';

const SUPPORT_URL = 'url';
const SUPPORT_EMAIL = 'email';
const SUPPORT_PHONE = 'phone';

const SUPPORT_KEYS = [SUPPORT_URL, SUPPORT_EMAIL, SUPPORT_PHONE];
const _ = navigator.mozL10n.get;

const handleClick = (key, value) => () => {
  switch (key) {
    case SUPPORT_URL:
    case 'privacy':
      window.open(value);
      break;
    case SUPPORT_EMAIL:
      window.open(`mailto:${value}`);
      break;
    case SUPPORT_PHONE:
      window.open(`tel:${value}`);
      break;
    default:
      break;
  }
};

const supportIcons = {
  url: 'ime-language',
  email: 'email',
  phone: 'phone',
};

const SupportButton = ({ supportKey, supports }) => (
  <button
    onClick={handleClick(supportKey, supports[supportKey])}
    className="support-button"
    type="button"
  >
    <div>
      <i data-icon={supportIcons[supportKey]} />
      <span>{_(`support-${supportKey}`)}</span>
    </div>
  </button>
);

const SupportLinks = ({ supports, validSupportKeys }) =>
  validSupportKeys.length > 1 ? (
    <>
      <kai-1line-listitem text={_('customer-support')} />
      <div className="multiple-supports-wrapper">
        {SUPPORT_KEYS.map(
          supportKey =>
            supports[supportKey] && (
              <SupportButton supportKey={supportKey} supports={supports} />
            )
        )}
      </div>
    </>
  ) : (
    <kai-1line-listitem
      text={_('customer-support')}
      onClick={handleClick(Object.keys(supports)[0], Object.value(supports)[0])}
    />
  );

const DeveloperLinkSection = ({ developer }) => {
  const { privacy, supports, url } = developer;
  const validSupportKeys = supports
    ? Object.keys(supports).filter(key => SUPPORT_KEYS.includes(key))
    : [];
  return (
    <div className="links-section">
      {privacy && (
        <kai-1line-listitem
          text={_('term-privacy-policy')}
          onClick={handleClick('privacy', privacy)}
        />
      )}
      {validSupportKeys.length > 0 && (
        <SupportLinks supports={supports} validSupportKeys={validSupportKeys} />
      )}
      {url && (
        <kai-1line-listitem
          text={_('website')}
          onClick={handleClick('url', url)}
        />
      )}
    </div>
  );
};

export default DeveloperLinkSection;
