import React from 'react';
import { formatBytes, generateCategoryName, generateLanguages } from '../utils';
import PanelManager from '@/panel-manager';

const _ = navigator.mozL10n.get;

const VersionListItem = ({ version }) => (
  <kai-2lines-listitem text={version} subtext={_('version')} subtextontop />
);

const SizeListItem = ({ size }) => (
  <kai-2lines-listitem
    text={size ? formatBytes(size) : null}
    subtext={_('size')}
    subtextontop
  />
);

const showDeveloper = developer => {
  PanelManager.request({
    panel: 'developer',
    details: { developer },
  });
};

const DeveloperListItem = ({ developer }) => (
  <kai-2lines-listitem
    text={developer.name}
    subtext={_('developer')}
    onClick={() => showDeveloper(developer)}
    subtextontop
  />
);

const CategoryListItem = ({ categoryList }) => (
  <kai-2lines-listitem
    text={generateCategoryName(categoryList)}
    subtext={_('category')}
    subtextontop
  />
);

const LanguageListItem = ({ supportedLanguages }) => (
  <kai-2lines-listitem
    text={generateLanguages(supportedLanguages)}
    subtext={_('language')}
    subtextontop
  />
);

const DetailList = ({
  size,
  developer,
  categoryList,
  locales,
  isCore,
  version,
}) =>
  isCore ? (
    <div className="leftover-detail-wrapper">
      <VersionListItem version={version} />
      <SizeListItem size={size} />
    </div>
  ) : (
    <div className="leftover-detail-wrapper">
      <DeveloperListItem developer={developer} />
      <SizeListItem size={size} />
      <VersionListItem version={version} />
      <CategoryListItem categoryList={categoryList} />
      <LanguageListItem locales={locales} />
    </div>
  );

export default DetailList;
