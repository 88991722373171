import AppStore from '@/app-store';
import PanelManager from '@/panel-manager';
import ToastHelper from '@/helper/toast-helper';
import ViewsHelper from '@/helper/views-helper';

const INLINE_ACTIVITY_NAME = ['inline-open-page', 'inline-open-by-name'];

class MozActivityManager {
  constructor() {
    this.taskQueue = [];
    /*
     * While using postResult to exit, we should preventDefault the back key
     * event. Otherwise, it will close the caller app as well.
     */
    this.shouldPostResultToCaller = false;
  }

  saveToQueue(option) {
    if (!option) {
      return;
    }
    this.taskQueue.push(option);
    window.dispatchEvent(new CustomEvent('task-queue-updated'));
  }

  get task() {
    if (this.taskQueue.length === 0) {
      return false;
    }
    return this.taskQueue.pop();
  }

  async findActiveAppByTask(task) {
    const { name: taskName } = task;
    const { type: taskType } = task.data;
    let graphQLQuery = null;
    let application = null;

    switch (taskType) {
      case 'name': {
        const { name } = task.data;
        application = AppStore.findAppByName(name);
        graphQLQuery = { name };
        break;
      }
      case 'url':
      case 'push-app': {
        const { url } = task.data;
        const { searchParams } = new URL(url);
        const activeManifestURL =
          taskName === 'open-deeplink' ? searchParams.get('apps') : url;
        application = AppStore.findAppByManifest(activeManifestURL);
        graphQLQuery = { manifestURL: activeManifestURL };
        break;
      }
      default:
        console.warn(`invalid activity task`, task);
        return null;
    }

    if (application && application.isRemote) {
      return application;
    }

    try {
      const graphQLApp = await AppStore.fetchGraphQL(graphQLQuery);
      return graphQLApp;
    } catch (err) {
      console.warn('failed to fetchApp via graphQL helper', err);
      return null;
    }
  }

  async handleTask(task, isFromLaunch = false) {
    const { name: taskName } = task;
    const { type: taskType, adId, autoDownload, pixel } = task.data;
    if (taskName === 'silent-launch') {
      return;
    }

    const disposition = INLINE_ACTIVITY_NAME.includes(taskName)
      ? 'inline'
      : 'window';
    let shouldSearchForApp = true;
    let activeApp = null;

    if (taskName === 'open-deeplink') {
      const { url } = task.data;
      const { searchParams } = new URL(url);
      const activeManifestURL = searchParams.get('apps');
      const needPostResult = searchParams.get('postResult') === 'true';
      /*
       * If store is launched via deeplink (app://kaios-store.kaiostech.com)
       * and without having 'apps' param, we should not search for any app
       */
      shouldSearchForApp = !!activeManifestURL;
      this.shouldPostResultToCaller = needPostResult;
    }

    if (shouldSearchForApp) {
      activeApp = await this.findActiveAppByTask(task);
    }

    if (activeApp && activeApp.id) {
      if (isFromLaunch && INLINE_ACTIVITY_NAME.includes(taskName)) {
        PanelManager.appendAppsPanelHistory();
      }
      PanelManager.request({
        panel: 'page',
        details: {
          id: activeApp.id,
          directDownload: taskType === 'push-app' || autoDownload,
          disposition,
          entry: 'activity',
          ...(adId && { adId }),
          ...(pixel && { pixel }),
        },
      });
    } else if (shouldSearchForApp) {
      ToastHelper.showMsg('not-found-msg');
      PanelManager.requestAppsPanel(isFromLaunch);
    }
    ViewsHelper.notifyUIReady();
  }
}

export let mozActivityManager = new MozActivityManager();
