import DialogHelper from '@/helper/dialog-helper';
import Account from '@/account';
import PanelManager from '@/panel-manager';
import dynamicCategory from '@/helper/categories-helper';
import { deviceUtils } from '@/device-utils';
import { SIZE_OPTIONS } from './constant';

const _ = navigator.mozL10n.get;

export const generateLanguages = (locales = []) => {
  const languages = locales
    .map(key => _(`lang-${key}`))
    // filter out l10n not found
    .filter(lang => lang !== '');
  const langString = languages.length > 0 ? languages.join(', ') : '';
  return langString;
};

export const generateCategoryName = categoryList => {
  const categories = dynamicCategory.getCategoriesByCodes(categoryList);
  return categories.map(category => category.displayName).join(', ');
};

// copy from: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${SIZE_OPTIONS[i]}`;
};

export const isAppsMenuFull = () => {
  const key = 'home.appsMenu.isFull';
  return deviceUtils.settings.get(key);
};

export const handleMenuFull = () => {
  const menuFullDialog = {
    title: _('alert'),
    message: _('apps-menu-is-full'),
    primarybtntext: _('ok'),
    onPrimaryBtn: DialogHelper.hideDialog,
  };
  DialogHelper.showDialog(menuFullDialog);
};
