// FIXME: error handling is not standard: not rejecting with a message (which will be converted to Error)
import { MessageSender } from 'web-message-helper';
import { PaymentDetail } from 'kaistore-post-messenger/src/models';
import { RequestPaymentCommand } from 'kaistore-post-messenger/src/commands';
import { deviceUtils } from '../device-utils';
import { fetchThroughClient } from './http-request-helper';

class PaymentHelper {
  constructor() {
    this._apiPrefix = deviceUtils.settings.get('apps.serviceCenterUrl');
  }

  pay(detail) {
    return new Promise((resolve, reject) => {
      const { id, name } = detail;
      const command = new RequestPaymentCommand({
        detail: new PaymentDetail({
          id,
          name,
        }),
      });
      MessageSender.send(command, (success, cbDetail) => {
        const { receipt, error } = cbDetail;
        if (success) {
          resolve(receipt);
        } else {
          reject(error);
        }
      });
    });
  }

  getAllPurchased() {
    const url = `${this._apiPrefix}/products?purchased=true`;

    return new Promise((resolve, reject) => {
      fetchThroughClient(url).then(
        result => resolve(result),
        error => {
          error.url = url;
          reject(error);
        }
      );
    });
  }

  getPurchasedByProductId(productId) {
    const url = `${this._apiPrefix}/products/${productId}?purchased=true`;

    return new Promise((resolve, reject) => {
      fetchThroughClient(url).then(
        result => resolve(result),
        error => {
          error.url = url;
          reject(error);
        }
      );
    });
  }

  getReceiptByTransactionId(transactionId) {
    const url = `${this._apiPrefix}/transactions/${transactionId}/receipt`;
    return fetchThroughClient(url).catch(error => {
      const newError = { ...error, url };
      throw newError;
    });
  }
}
export default PaymentHelper;
