import React from 'react';
import PanelManager from '@/panel-manager';

const handleClick = searchIn => () => {
  PanelManager.request({
    panel: 'search',
    details: {
      searchIn,
    },
  });
};

const SearchButton = ({ searchIn }) => (
  <i
    slot="header-right-slot"
    data-icon="search"
    onClick={handleClick(searchIn)}
  />
);

export default SearchButton;
