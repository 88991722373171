/**
 * use REMOTE_URL_FOR_LOCAL_DEV to set the _remoteURL when connecting to local dev server
 * STAGE:
 *  SERVICE ON STORE: 'https://stage.kaios-plus.kaiostech.com'
 *  STAND ALONE GRAPHQL SERVICE: 'https://stage.api.store.kaiostech.com'
 * TEST:
 *  SERVICE ON STORE: 'https://test.kaios-plus.kaiostech.com'
 *  STAND ALONE GRAPHQL SERVICE: 'https://test.api.store.kaiostech.com'
 */
import { createQuerystringFromParams, utils } from '@/utils';
import { deviceUtils } from '../device-utils';

const REMOTE_URL_FOR_LOCAL_DEV = 'https://stage.api.store.kaiostech.com';
// Since there is no GraphQL service in BETA env, use PROD GraphQL service for it
const REMOTE_URL_FOR_BETA = 'https://api.store.kaiostech.com';

/** A class to handle store is launched by activity.
 */
class GraphQLHelper {
  /**
   * Initializes variables.
   */
  constructor() {
    // turn on _isLocalDev and change REMOTE_URL_FOR_LOCAL_DEV when connecting to local dev server
    this._isLocalDev = false;
    this._apiPrefix = deviceUtils.settings.get('apps.graphqlBaseUrl');
    this._apiKey = deviceUtils.settings.get('apps.graphql.key');
    this.locale = navigator.mozL10n.language.code || 'en-US';
    this._graphQLURL = this._getGraphQLURL();
  }

  /**
   * Get _remoteURL by from settings, specially handle for local dev and BETA env case
   * @return {String} _remoteURL of env
   * @private
   */
  _getRemoteUrl() {
    if (this._isLocalDev) {
      return REMOTE_URL_FOR_LOCAL_DEV;
    }
    const remoteURL = deviceUtils.settings.get('apps.serviceCenter.remoteUrl');
    return remoteURL.includes('beta') ? REMOTE_URL_FOR_BETA : this._apiPrefix;
  }

  _getDefaultParams() {
    const connection = deviceUtils.connection;
    const {
      defaultServiceId,
      currentMCC,
      currentMNC,
      currentMCC2,
      currentMNC2,
      imei,
    } = connection;
    const communicationParams = {
      mcc: defaultServiceId === 0 ? currentMCC : currentMCC2,
      mnc: defaultServiceId === 0 ? currentMNC : currentMNC2,
      imei: imei || '',
    };
    const deviceParams = {
      curef: deviceUtils.settings.get('deviceinfo.cu'),
      platform: deviceUtils.settings.get('deviceinfo.os').split(' ')[0],
      locale: navigator.mozL10n.language.code || 'en-US',
    };
    return { ...communicationParams, ...deviceParams };
  }

  _getGraphQLURL() {
    const defaultParams = this._getDefaultParams();
    const remoteURL = this._getRemoteUrl();
    const querystring = createQuerystringFromParams(defaultParams);
    return `${remoteURL}/graphql${querystring}`;
  }

  /**
   * Send an HTTP request using XMLHttpRequest.
   * @param {Object} paramsObj - All parameters you need to send a request.
   * @return {Promise} The xhr response / xhr error
   * @public
   */
  httpRequester(paramsObj) {
    return new Promise((resolve, reject) => {
      const { url, method, headers, payload } = paramsObj;
      const xhr = new XMLHttpRequest();

      xhr.open(method, url, true);
      // request header
      for (const key in headers) {
        xhr.setRequestHeader(key, headers[key]);
      }
      // response type
      xhr.responseType = 'json';

      xhr.onload = e => {
        const statusCode = e.target.status;
        if (statusCode >= 200 && statusCode < 400) {
          resolve(xhr.response);
        } else {
          reject(`${url}: ${e.target.statusText}`);
        }
      };
      xhr.onerror = () => {
        reject(`${url}: No response from server.`);
      };
      xhr.send(payload);
    });
  }

  /**
   * Find an app by inline activity object via graphql API. (Fully matching)
   * @param {object} graphQLQuery - The graphQL object. key: 'name' or 'manifestURL', value: name or manifestURL value
   * @param {boolean} shouldReturnYMAL - true if YMAL data should be returned
   * @return {Promise} The app data or null if there is no this app.
   * @public
   */
  fetchApp(graphQLQuery, shouldReturnYMAL = false) {
    const searchCriteria = {
      name: 'name',
      manifestURL: 'manifest_url',
    };
    const [key, value] = Object.entries(graphQLQuery)[0];

    const params = {
      url: this._graphQLURL,
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'x-api-key': this._apiKey,
      },
      payload: this._generatePayload(
        searchCriteria[key],
        value,
        shouldReturnYMAL
      ),
    };

    /**
     * If search api doesn't work, we will fallback to the original way to
     * open the app detail page.
     */
    return this.httpRequester(params).then(result => result.data.getApp[0]);
  }

  /**
   * Generate graphql payload from according to the key and value of inline activity object.
   * @param {String} key - name or manifest_url
   * @param {String} value - name or manifestURL value
   * @param {Boolean} shouldReturnYMAL - true if YMAL should be returned
   * @private
   */
  _generatePayload(key, value, shouldReturnYMAL) {
    const basicProperties = `
      id,
      name,
      theme,
      version,
      packaged_size,
      icons {size_56,size_128},
      create_at,
      default_locale,
      display,
      manifest_url,
      bgs {size_240},
      bundle_id,
      paid,
      supported_platforms,
      developer {name,url},
      locales {${
        // graphQL limitation: key can't contain '-', so change 'en-US' to 'en_US'
        this.locale.replace(/-/g, '_')
      } {name,subtitle,description}, default {name,subtitle,description}},
      description,
      type,
      default_locale,
      product_id,
      category_list
    `;
    const ymalProperties = `recommendation_id, is_sponsored, ${basicProperties}`;
    const appProperties = shouldReturnYMAL
      ? `${basicProperties}, ymal {${ymalProperties}}`
      : basicProperties;
    return `query {
      getApp(${key}:"${value}") {${appProperties}}
    }`;
  }
}

export default GraphQLHelper;
