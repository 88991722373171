import React, { Component } from 'react';

import PanelManager from '../panel-manager';

import '../../scss/panel/ErrorPanel.scss';
import '../../scss/status.scss';

const _ = navigator.mozL10n.get;

class ErrorPanel extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(this.props.detail) !== JSON.stringify(nextProps.detail)
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { origin, status } = this.props.detail;
    return (
      <div className="error-panel">
        <kai-header primarytitle={origin} />
        <div>
          <div className="status-img-wrapper">
            <div className="status-img" data-status={status} />
          </div>
          <div className="error-msg-wrapper">
            <div className="content" data-l10n-id={status} />
          </div>
          <div className="btn-wrapper">
            <kai-pillbutton
              level="secondary"
              text={_('back')}
              onClick={() => {
                PanelManager.back();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPanel;
