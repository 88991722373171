import React from 'react';

const _ = navigator.mozL10n.get;

export default ({ onClick, isVisible }) => {
  if (!isVisible) {
    return null;
  }
  return (
    <div className="read-more-overlay">
      <span className="button" data-l10n-id="read-more" onClick={onClick} />
    </div>
  );
};
