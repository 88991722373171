export const createQuerystringFromParams = params => {
  const queries = Object.entries(params).reduce(
    (acc, [key, value]) => acc.concat(`&${key}=${value}`),
    ''
  );
  return queries.replace('&', '?');
};

export const checkIsUpdatableApp = application => {
  const { mozApp } = application;
  /**
   * If the application is still downloading or being cancelled during
   * downloading, there would be no manifest field
   */
  const hasBothManifest = mozApp.manifest && mozApp.updateManifest;
  if (
    !hasBothManifest ||
    !isVersionHigher(mozApp.manifest.version, mozApp.updateManifest.version) ||
    application.isInternal ||
    application.isPreloadedApp ||
    !application.isInstalled
  ) {
    return false;
  }
  return true;
};

// Used as fallback for getRemoteLocales() or handle mozApp locale
export const trimLocales = (locales, langCode) => {
  if (!locales || !langCode) {
    return {};
  }
  /*
   * XXX: Since there is a limitation from graphql (not allowed having "-" in
   * attribute), to prevent the page cannot get the locale properly, let's
   * check lang (e.g. en-US) first. If locale is not found, then check lang
   * that replaces "-" with "_" (e.g. en_US).
   */
  return (
    locales[langCode] ||
    locales[langCode.replace(/-/g, '_')] ||
    // default is a special key in graphQL response
    locales.default ||
    Object.values(locales)[0] ||
    {}
  );
};

export const isVersionHigher = (oldVersion, newVersion) => {
  let oldVerDigits = oldVersion.split('.').map(digit => parseInt(digit, 10));
  let newVerDigits = newVersion.split('.').map(digit => parseInt(digit, 10));

  // filling 0 if number of the digits are not the same
  const missingDigit = Math.abs(oldVerDigits.length - newVerDigits.length);
  if (missingDigit > 0) {
    if (oldVerDigits.length > newVerDigits.length) {
      newVerDigits = newVerDigits.concat(Array(missingDigit).fill(0));
    } else {
      oldVerDigits = oldVerDigits.concat(Array(missingDigit).fill(0));
    }
  }

  for (let i = 0; i < oldVerDigits.length; i++) {
    const oldDigit = oldVerDigits[i];
    const newDigit = newVerDigits[i];
    if (newDigit > oldDigit) {
      return true;
    }
    if (oldDigit > newDigit) {
      return false;
    }
  }
  return null; // return null as equal
};
