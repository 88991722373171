import dynamicCategory from '@/helper/categories-helper';

const _ = navigator.mozL10n.get;

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const generateLanguages = (languages = []) => {
  return languages.length > 0
    ? languages
        .map(key => _(`lang-${key}`))
        // filter out l10n not found
        .filter(lang => lang !== '')
        .join(', ')
    : '';
};

export const generateCategoryName = categoryList => {
  const categories = dynamicCategory.getCategoriesByCodes(categoryList);
  return categories.map(category => category.displayName).join(', ');
};
