import { createQuerystringFromParams } from '../utils';
import { deviceUtils } from '../device-utils';
import { fetchThroughClient } from './http-request-helper';
import appStore from '../app-store/app-store';

class SearchHelper {
  init() {
    const { simMCC, simMNC, imei } = deviceUtils.connection;
    this.params = {
      // TODO: handle pagination later
      page: 1,
      size: 1000,
      platform: deviceUtils.settings.get('deviceinfo.platform_version'),
      mcc: simMCC,
      mnc: simMNC,
      imei: imei,
      curef: deviceUtils.settings.get('deviceinfo.cu'),
      locale: navigator.mozL10n.language.code || 'en-US',
      bookmark: appStore.isBookmarkDBSupported,
    };
    this.searchBaseUrl = deviceUtils.settings.get('apps.searchBaseUrl');
  }

  searchApps(query) {
    const params = { ...this.params, query };
    return fetchThroughClient(
      `${this.searchBaseUrl}/v3/_search${createQuerystringFromParams(params)}`,
      {
        toRemoveControlChars: true,
      }
    )
      .then(res => {
        return res.organic || [];
      })
      .catch(e => {
        console.error(e);
        throw e;
      });
  }
}

export default new SearchHelper();
