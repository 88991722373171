import React, { PureComponent } from 'react';
import PanelManager from '../panel-manager';
import AppIcon from './AppIcon';

import '../../scss/component/AppItem.scss';

class AppItem extends PureComponent {
  constructor(props) {
    super(props);
    this.panelManager = PanelManager;
  }

  showApp = () => {
    this.panelManager.request({
      panel: 'page',
      details: { id: this.props.app.id, appOrder: this.props.order },
    });
  };

  showLink = () => {
    const { url } = this.props.app.info;
    if (url) {
      window.open(url, '_blank');
    }
  };

  render() {
    const {
      app: { info, localized },
      isDownloading = false,
      showSubtitle = true,
    } = this.props;
    const { name, subtitle } = localized;
    const subtext =
      info.type === 'bookmark' ? navigator.mozL10n.get('website') : subtitle;

    return (
      <div
        className="app-item"
        tabIndex="1"
        data-id={app.id}
        data-type={info.type}
        data-name={name}
        data-app-version={app.version}
        ref={element => {
          this.element = element;
        }}
      >
        {info.type === 'link' ? (
          <div
            className="link-app"
            style={{ backgroundImage: `url('${Object.values(info.bgs)[0]}')` }}
            onClick={() => {
              this.showLink();
            }}
          />
        ) : (
          <kai-2lines-listitem
            subtext={showSubtitle ? subtext : null}
            onClick={() => {
              this.showApp();
            }}
          >
            <div slot="listitem-center-slot">
              <span className="title">{name}</span>
            </div>
            <div slot="listitem-left-slot">
              <AppIcon type={info.type} icon={info.icon} />
            </div>

            {isDownloading && (
              <div slot="listitem-right-slot" className="download-indicator">
                <i data-icon="update" />
              </div>
            )}
          </kai-2lines-listitem>
        )}
      </div>
    );
  }
}

export default AppItem;
