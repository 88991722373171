import React, { PureComponent } from 'react';

import 'scss/component/AppIcon.scss';

class AppIcon extends PureComponent {
  constructor(props) {
    super(props);
    this.initState = {
      isLoaded: false,
      isPWAIcon: typeof props.icon === 'object' && props.icon !== null,
    };
    this.state = this.initState;
  }

  componentDidUpdate(prevProps) {
    // Reset state for shared panel such as PagePanel, UpdatePagePanel
    if (prevProps.icon !== this.props.icon) {
      this.resetState();
    }
    if (
      typeof this.props.icon === 'object' &&
      this.props.icon !== null &&
      this.state.isPWAIcon === false
    ) {
      this.setState({ isPWAIcon: true });
    }
  }

  resetState = () => this.setState(this.initState);

  handleLoad = () => {
    this.setState({ isLoaded: true });
  };

  render() {
    const { type, icon } = this.props;
    const { isLoaded, isPWAIcon } = this.state;
    return (
      <div
        // if app is a bookmark, load a globe as bg-img; else do nothing
        className={
          type === 'bookmark'
            ? 'app-icon-component bookmark-globe'
            : 'app-icon-component'
        }
      >
        <div
          className={
            // use default app icon as background img before the real icon is loaded or failed to load
            isLoaded || isPWAIcon
              ? 'icon-wrapper'
              : 'icon-wrapper default-background'
          }
        >
          {typeof icon === 'object' && icon !== null ? (
            <div
              className="pwa-icon"
              style={{ backgroundColor: icon.backgroundColor }}
            >
              <span>{icon.text}</span>
            </div>
          ) : (
            <img
              src={icon}
              // hide img tag if failed to load
              className={isLoaded ? 'app-icon' : 'app-icon hide'}
              alt="app-icon"
              onLoad={this.handleLoad}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AppIcon;
