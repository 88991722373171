import React from 'react';
import AppIcon from '@/component/AppIcon';
import PanelManager from '@/panel-manager';

import 'scss/component/AppItem.scss';

const showApp = manifestURL => () => {
  PanelManager.request({
    panel: 'updatePage',
    details: { manifestURL },
  });
};

const AppItem = ({ application }) => {
  const { info, localized, mozApp, status, manifestURL } = application;
  const { name } = localized;
  const showIndicator = status.downloading || mozApp.isDownloadPreparing;
  return (
    <div
      className="app-item"
      tabIndex="1"
      data-id={app.id}
      data-type={info.type}
      data-name={name}
      data-app-version={app.version}
    >
      <kai-2lines-listitem text={name} onClick={showApp(manifestURL)}>
        <div slot="listitem-left-slot">
          <AppIcon type={info.type} icon={info.icon} />
        </div>

        {showIndicator && (
          <div slot="listitem-right-slot" className="download-indicator">
            <i data-icon="update" />
          </div>
        )}
      </kai-2lines-listitem>
    </div>
  );
};

export default AppItem;
