import React from 'react';
import AppIcon from '@/component/AppIcon';

const _ = navigator.mozL10n.get;

const InfoRow = ({ subtitle, name, type, icon, bookmarkURL }) => (
  <div className="info-row">
    <div className="left">
      <AppIcon type={type} icon={icon} />
    </div>
    <div className="right">
      <h2 className="title">{name}</h2>
      {type === 'bookmark' ? (
        <div className="website-info">
          <div className="title">{_('website-app')}</div>
          <div className="website-url">{bookmarkURL}</div>
        </div>
      ) : (
        <div className="subtitle sub-2">{subtitle}</div>
      )}
    </div>
  </div>
);

export default InfoRow;
