import React from 'react';
import { ACTIONS } from '../constant';

const _ = navigator.mozL10n.get;

const PaymentSection = ({ paid, purchased, unitPrice }) => {
  if (paid) {
    if (purchased) {
      return (
        <div className="paid-wrapper">
          <div className="status-icon" data-enable="true">
            <i data-icon="tick" />
          </div>
          <div className="status">{_('purchased')}</div>
        </div>
      );
    }
    if (!unitPrice) {
      return (
        <div className="paid-wrapper">
          <div className="status-icon" data-enable="false" />
        </div>
      );
    }
    if (unitPrice) {
      return (
        <div className="paid-wrapper">
          <div className="sub-1">{unitPrice || '-'}</div>
          <div className="kaipay-logo-wrapper">
            <div className="logo" />
          </div>
        </div>
      );
    }
  }
  return <></>;
};

const ActionRow = ({ action, handleAction, paid, purchased, unitPrice }) => (
  <div className="action-row">
    <div className="left">
      <PaymentSection paid={paid} purchased={purchased} unitPrice={unitPrice} />
    </div>
    <div className="right">
      <kai-pillbutton
        level="primary"
        text={_(action)}
        action={action}
        onClick={handleAction(action)}
        disabled={action === ACTIONS.DISABLEBUY ? true : false}
      />
    </div>
  </div>
);

export default ActionRow;
