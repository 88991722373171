import { RequestInstalledAppsCommand } from 'kaistore-post-messenger/src/commands';
import { MessageSender } from 'web-message-helper';

// TODO: Should be removed in the future.
const releaseUnusedInfo = mozApp => {
  const manifest = mozApp.manifest || mozApp.updateManifest;
  const { locales } = manifest;

  delete manifest.description;
  if (locales) {
    for (const locale in locales) {
      if ({}.hasOwnProperty.call(locales, locale)) {
        delete locales[locale].description;
      }
    }
  }
  return mozApp;
};

const getInstalledMozApps = () => {
  return new Promise((resolve, reject) => {
    MessageSender.send(new RequestInstalledAppsCommand(), (success, detail) => {
      if (success) {
        resolve(detail);
      }
      reject(new Error('Failed to get installed apps from client'));
    });
  });
};

const getMozApps = manifestURLs => {
  return new Promise(resolve => {
    getInstalledMozApps().then(mozApps => {
      const matchedMozApp = Object.keys(mozApps)
        .filter(manifestURL => manifestURLs.includes(manifestURL))
        .map(manifestURL => {
          const mozApp = mozApps[manifestURL];
          releaseUnusedInfo(mozApp);
          return mozApp;
        });
      resolve(matchedMozApp);
    });
  });
};

export default {
  getInstalledMozApps,
  getMozApps,
  releaseUnusedInfo,
};
