export const DOWNLOAD_CANCEL_ERROR = 'DOWNLOAD_CANCELED';
export const DEFAULT_PAGE_SIZE = 20;
export const INIT_PAGE_NUMBER = 1;
export const EXPLORE_CATEGORY_CODE = 200;
export const CACHE_TIME_LIMIT = 1 * 60 * 60 * 1000;
export const ALL_APPS_KEY = 'ALL_APPS';

export const SUPPORTED_TYPE = [
  'bookmark',
  'privileged',
  'hosted',
  'certified',
  'web',
  'pwa',
  'openpwa',
  'link',
];

export const STATUS = {
  DOWNLOADING: 'downloading',
  INSTALLED: 'installed',
  UPDATABLE: 'updatable',
  PURCHASED: 'purchased',
  PIN: 'pinStatus',
};
