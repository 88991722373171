// TODO Enhance this.state
// TODO Enhance event listener, and handler.

import regeneratorRuntime from 'regenerator-runtime';

import 'l10n/l10n';
import './lib/async_storage';
// Libs
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import 'gaia-icons-dist/gaia-icons.css';

import 'shared/src/components/header';
import 'shared/src/components/categorybar';
import 'shared/src/components/1linelist';
import 'shared/src/components/2lineslist';
import 'shared/src/components/progress';
import 'shared/src/components/pillbutton';
import 'shared/src/components/dialog';
import 'shared/src/components/separator';
import 'shared/src/components/searchbar';

import { MessageListener, MessageSender } from 'web-message-helper';

import Account from '@/account';
import AppStore from '@/app-store';
import { mozActivityManager } from '@/mozactivity-manager';
import { deviceUtils } from '@/device-utils';
import PanelManager from '@/panel-manager';
import dynamicCategory from '@/helper/categories-helper';
import NetworkHelper from '@/helper/network-helper';
import ToastHelper from '@/helper/toast-helper';
import SearchHelper from '@/helper/search-helper';
import ViewsHelper from '@/helper/views-helper';
import receiveMessage from '@/helper/message-receiver';
import { APP_HTTP_ORIGIN, SECTION_NAME } from '@/constants';
import '@/lib/debug-error';

// Component
import Panel from '@/Panel';

// Panel
import DeveloperPanel from './panel/DeveloperPanel';
import MorePanel from './panel/MorePanel';
import AppListPanel from './panel/AppListPanel';
import LoadingPanel from './panel/LoadingPanel';
import PagePanel from './panel/PagePanel';
import UpdatePagePanel from './panel/UpdatePagePanel';
import SettingsPanel from './panel/SettingsPanel';
import ScreenshotPanel from './panel/ScreenshotPanel';
import UpdatePanel from './panel/UpdatePanel';
import DownloadHistoryPanel from './panel/DownloadHistoryPanel';
import ErrorPanel from './panel/ErrorPanel';
import analyticsHelper from './helper/analytics-helper';
import SearchPanel from './panel/SearchPanel';

// CSS
import 'shared/style/theme.css';
import 'scss/app.scss';
import 'scss/common.scss';
import 'scss/largetext.scss';

const KEY_SERVER_TIME_OFFSET = 'hawk-request-server-time-offset';
const _ = navigator.mozL10n.get;
const MORE_PANEL_DEFAULT_PAGE = 'more';

class App extends Component {
  constructor() {
    super();
    // get default state.
    this.state = this.getInitialState();
    this.activityRequest = null;
    // panel history recorder.
    this.panelHistory = [];
    MessageSender.remoteReady = true;
    MessageSender.postFullQueue();
    this.initialized = false;
    this.refs = {};
    this.categorybarRef = React.createRef();
    this.dialogRef = React.createRef();
    // isResumeFromAccount: true if 'account:login' or 'account:logout'
    // the store should return to the page before launching Account UI
    this.isResumeFromAccount = false;
    Account.init();
    this.startMessengers();
  }

  getInitialState() {
    const initialState = {
      needUpdate: false,
      dialog: false,
      currentAppId: null,
      currentUpdateMozAppManifestURL: null,
      currentCategory: null,
      currentSection: PanelManager.currentSection,
      dialogOptions: {},
      shouldDirectDownload: false,
      showCategoryBar: false,
      // for screenshots panel
      images: [],
      imageIndex: 0,
      // for developer panel
      developer: {},
      searchIn: null,
      errorPageDetail: {
        status: null,
        origin: null,
      },
      morePanelPage: MORE_PANEL_DEFAULT_PAGE,
      hasVisitedMorePanel: false,
      numberOfUpdatableApps: 0,
    };
    return initialState;
  }

  resetState() {
    const initialState = this.getInitialState();
    // reset history in PanelManager
    PanelManager.init();
    this.isResumeFromAccount = false;
    this.setState(initialState);
  }

  boot() {
    deviceUtils.requestInfo().then(() => {
      this.init();
    });
  }

  reboot() {
    this.resetState();
    PanelManager.request({ panel: 'loading' }, false);
    // reset appStore.
    this.appStore.resetStore();

    // re-fetch device info.
    deviceUtils.getDeviceInfos().then(() => {
      this.startRemoteServices();
    });
  }

  init() {
    // register panels at first step to make sure UI can be shown.
    this.initPanelList();

    // Listen relative events
    this.startEventListener();

    this.appStore = AppStore;

    // Get the time offset from indexedDB if it exists
    asyncStorage.getItem(KEY_SERVER_TIME_OFFSET, result => {
      window.serverTimeOffset = result;
    });

    // then start
    this.start();
  }

  resumeFromAccount() {
    this.isResumeFromAccount = true;
    this.start();
  }

  start() {
    PanelManager.request({ panel: 'loading' }, this.isResumeFromAccount);
    // show no network UI and early return here.
    if (!NetworkHelper.online) {
      // reset state to make sure UI works correctly.
      this.resetState();
      ToastHelper.showMsg('no-internet');
      console.error('no network');
      return;
    }

    // Start to request things from server,
    // such as token, app-list, categories and service worker.
    this.startRemoteServices();
  }

  async startRemoteServices() {
    const searchParams = new URLSearchParams(window.location.search);
    const disposition = searchParams.get('disposition');
    const manifestURL = searchParams.get('manifest');
    const name = searchParams.get('name');
    if (manifestURL || name) {
      mozActivityManager.saveToQueue({
        name:
          (disposition === 'inline' ? 'inline-' : '') +
          'open-' +
          (manifestURL ? 'page' : 'by-name'),
        data: {
          type: manifestURL ? 'url' : 'name',
          url: manifestURL,
          name,
        },
      });
    }

    const version = searchParams.get('version');
    analyticsHelper.init({
      version,
    });

    await this.appStore.initAsync();
    SearchHelper.init();

    await dynamicCategory.getCategories();
    await this.appStore.startWithAppsSection();

    this.prepareApps();
  }

  stop() {
    this.stopEventListener();
  }

  kaiDialogClickHandler = event => {
    const {
      detail: { type },
    } = event;
    const { dialogOptions } = this.state;
    if (type === 'primary' && dialogOptions.onPrimaryBtn) {
      dialogOptions.onPrimaryBtn();
    }
    if (type === 'secondary' && dialogOptions.onSecondaryBtn) {
      dialogOptions.onSecondaryBtn();
    }
  };

  startEventListener() {
    window.addEventListener('networkstatuschange', this.start.bind(this));
    window.addEventListener('account:login', this.resumeFromAccount.bind(this));
    window.addEventListener(
      'account:logout',
      this.resumeFromAccount.bind(this)
    );
    window.addEventListener('server:error', this.serverError.bind(this));
    window.addEventListener('reboot-needed', this.reboot.bind(this));

    window.addEventListener('keydown', this.handleKeydown);

    window.addEventListener(
      'hawkrequester:offsetchange',
      this.handleHawkOffsetChange.bind(this)
    );
    window.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange.bind(this)
    );
    window.addEventListener(
      'appstore:change',
      this.handleStoreChange.bind(this)
    );
    window.addEventListener('dialog:show', this.showDialog);
    window.addEventListener('dialog:hide', this.hideDialog);

    // handle kai-categorybar select
    this.categorybarRef.current.addEventListener(
      'select',
      this.kaiCategoryBarSelectHandler
    );

    // handle kai-dialog button click
    this.dialogRef.current.addEventListener(
      'click',
      this.kaiDialogClickHandler
    );

    PanelManager.on('change', this.handlePanelChange.bind(this));
    PanelManager.on('sectionChanged', () => {
      this.setState({ currentSection: PanelManager.currentSection });
    });
  }

  async prepareApps() {
    this.initialized = true;
    // If mozActivityTask isn't empty,
    // launch page for showing specific app.
    const mozActivityTask = mozActivityManager.task;

    analyticsHelper.setReferralMetaByActivity(mozActivityTask);

    const hasAccountAfterLoginRequest = Account.afterLoginRequest !== null;
    if (mozActivityTask) {
      await mozActivityManager.handleTask(mozActivityTask, true);
    } else if (hasAccountAfterLoginRequest) {
      this.handleAfterLoginRequest();
    } else {
      this.requestLaunchList();
    }

    // add eventListener after app list is ready
    // to avoid app is not found
    window.addEventListener('task-queue-updated', async () => {
      const { task } = mozActivityManager;
      if (task) {
        await mozActivityManager.handleTask(task);
      }
    });
  }

  handleHawkOffsetChange(event) {
    const offset = event.detail.current;
    window.serverTimeOffset = offset;
    asyncStorage.setItem(KEY_SERVER_TIME_OFFSET, offset);
  }

  handleVisibilityChange() {
    if (document.visibilityState === 'hidden') {
      if (this.activityRequest) {
        // To notify Activity requester store app will be closed.
        this.activityRequest.postResult('close-store-app');
      }
      window.close();
    }
  }

  handleAfterLoginRequest() {
    if (Account.isLoggedIn && Account.afterLoginRequest !== null) {
      const { section, panelInfo } = Account.afterLoginRequest;
      if (section) {
        PanelManager.currentSection = section;
      }
      PanelManager.request(panelInfo);
      Account.afterLoginRequest = null;
    }
  }

  handleStoreChange(e) {
    const { detail } = e;
    // detail could be absent for application since it doesn't extend basemodule
    if (!detail) return;
    switch (detail.type) {
      case 'request-toast':
        ToastHelper.showMsg(detail.msg);
        break;
      // XXX: since the data in appstore is NOT passed in as a prop, we have to copy updatableApplications.length to numberOfUpdatableApps as state
      // and use event to update the state when necessary to trigger re-render
      case 'update-number-of-updatable-apps': {
        this.setState({
          numberOfUpdatableApps: this.appStore.updatableApplications.length,
        });
        break;
      }
      default:
        break;
    }
  }

  handlePanelChange(parameters) {
    // Just like router to decide launch panel and pass parameters.
    // If not found, show apps panel and prompt dialog.
    const { panel, details } = parameters;

    switch (panel) {
      case 'page': {
        this.setState({
          // details.directDownload has value only when triggered by activities, convert undefined to false
          shouldDirectDownload: !!details.directDownload,
          currentAppId: details.id,
        });
        break;
      }
      case 'updatePage': {
        this.setState({ currentUpdateMozAppManifestURL: details.manifestURL });
        break;
      }
      case 'applist':
        this.setState({ currentCategory: details.category });
        break;
      case 'screenshot':
        this.setState({
          images: details.images,
          imageIndex: details.index,
          showCategoryBar: false,
        });
        break;
      case 'developer':
        this.setState({ developer: details.developer, showCategoryBar: false });
        break;
      case 'search':
        this.setState({ searchIn: details.searchIn });
        break;
      case 'error':
        this.setState({
          errorPageDetail: {
            status: details.status,
            origin: details.origin,
          },
        });
        break;
      case 'more':
        this.setState({
          morePanelPage: details.page ? details.page : 'more',
        });
        break;
      default:
        break;
    }
    this.activatePanel(panel);
  }

  serverError(e) {
    const { detail } = e;
    if (detail) {
      ToastHelper.showMsg(detail);
    }
  }

  stopEventListener() {
    // TODO
  }

  componentDidMount() {
    window.app = this; // For debugging purpose
    this.boot();
  }

  componentWillUnmount() {
    this.categorybarRef.current.removeEventListener(
      'select',
      this.kaiCategoryBarSelectHandler
    );

    this.dialogRef.current.removeEventListener(
      'click',
      this.kaiDialogClickHandler
    );
  }

  initPanelList() {
    const panels = Object.keys(this.refs).reduce((accPanel, panelKey) => {
      const ref = this.refs[panelKey];
      if (ref.path) {
        accPanel.push(panelKey);
      }

      return accPanel;
    }, []);

    PanelManager.register(panels);
  }

  requestLaunchList() {
    if (!this.appStore.isRemoteInfoSet) {
      return;
    }
    if (this.isResumeFromAccount) {
      PanelManager.back();
    } else {
      PanelManager.requestAppsPanel(true);
      ViewsHelper.notifyUIReady();
    }
  }

  activatePanel(panel) {
    let currentPanel;
    const historys = this.panelHistory.length;
    if (historys !== 0) {
      currentPanel = this.panelHistory[historys - 1];
      this.hidePanel(currentPanel);
    }
    this.panelHistory.push(panel);
    this.showPanel(panel);
  }

  hidePanel(panel) {
    if (this.refs[panel]) {
      this.refs[panel].hide();
    }
  }

  showPanel(panel) {
    if (this.refs[panel]) {
      this.refs[panel].show();
    }
    const fullScreenPanel = ['loading', 'screenshot', 'developer'];
    if (
      !fullScreenPanel.includes(panel) &&
      this.state.showCategoryBar === false
    ) {
      this.setState({ showCategoryBar: true });
    } else if (
      fullScreenPanel.includes(panel) &&
      this.state.showCategoryBar === true
    ) {
      this.setState({ showCategoryBar: false });
    }
  }

  showDialog = event => {
    const { options } = event.detail;
    this.setState({
      dialog: true,
      dialogOptions: options,
    });
  };

  hideDialog = callback => {
    this.setState(
      {
        dialog: false,
        dialogOptions: {},
      },
      () => {
        if (typeof callback === 'function') {
          callback();
        }
      }
    );
  };

  kaiCategoryBarSelectHandler = event => {
    const section = event.detail.selected;
    this.hidePanel(PanelManager.currentPanel);
    if (PanelManager.currentSection === section) {
      // same section
      PanelManager.resetHistoryBySection(section);
    } else {
      // switch section
      PanelManager.currentSection = section;
      const sectionVisited =
        PanelManager.history[section] &&
        PanelManager.history[section].length > 0;
      if (sectionVisited) {
        const nextPanel = PanelManager.history[section].pop();
        PanelManager.request(nextPanel);
      } else {
        if (section === SECTION_NAME.APPS) {
          PanelManager.requestAppsPanel();
        } else if (section === SECTION_NAME.GAMES) {
          PanelManager.requestGamesPanel();
        } else if (section === SECTION_NAME.DISCOVER) {
          PanelManager.requestDiscoverPanel();
        } else if (section === SECTION_NAME.MORE) {
          PanelManager.request({
            panel: SECTION_NAME.MORE,
            details: {
              page: MORE_PANEL_DEFAULT_PAGE,
            },
          });
          const { hasVisitedMorePanel } = this.state;
          if (!hasVisitedMorePanel) {
            this.setState({ hasVisitedMorePanel: true });
          }
        } else {
          PanelManager.request({ panel: section });
        }
      }
    }
  };

  handleKeydown = event => {
    if (event.key === 'GoBack') {
      event.stopPropagation();
      event.preventDefault();
      PanelManager.back();
    }
  };

  startMessengers() {
    MessageSender.validMessageOrigins = [APP_HTTP_ORIGIN];
    MessageSender.targetOrigin = APP_HTTP_ORIGIN;
    MessageSender.start();
    MessageListener.start(receiveMessage);
  }

  handleImageIndexUpdate = newIndex => {
    if (newIndex >= 0 && newIndex < this.state.images.length) {
      this.setState({ imageIndex: newIndex });
    }
  };

  render() {
    const { initialized } = this;
    const { hasVisitedMorePanel, numberOfUpdatableApps } = this.state;
    const mainMenu = [
      { label: _('apps'), value: SECTION_NAME.APPS, icon: 'rocket' },
      { label: _('discover'), value: SECTION_NAME.DISCOVER, icon: 'discover' },
      { label: _('more'), value: SECTION_NAME.MORE, icon: 'more-apps' },
    ];

    return (
      <div
        className={`App ${navigator.largeTextEnabled ? 'large-text' : ''}`}
        id="app"
        tabIndex="-1"
        ref={element => {
          this.element = element;
        }}
      >
        <Panel
          ref={loading => {
            this.refs.loading = loading;
          }}
          path="loading"
          visible={false}
        >
          <LoadingPanel />
        </Panel>

        {/* Main Panels */}
        <Panel
          ref={developer => {
            this.refs.developer = developer;
          }}
          path="developer"
          visible={false}
        >
          {initialized &&
            // XXX: Empty developer object from miniManifest will contains an empty supports object
            Object.keys(this.state.developer).length > 0 && (
              <DeveloperPanel developer={this.state.developer} />
            )}
        </Panel>
        <Panel
          ref={apps => {
            this.refs.apps = apps;
          }}
          path="apps"
          visible={false}
        />
        <Panel
          ref={games => {
            this.refs.games = games;
          }}
          path="games"
          visible={false}
        />
        <Panel
          ref={more => {
            this.refs.more = more;
          }}
          path="more"
          visible={false}
        >
          <MorePanel page={this.state.morePanelPage} />
        </Panel>
        <Panel
          ref={applist => {
            this.refs.applist = applist;
          }}
          path="applist"
          visible={false}
        >
          {this.state.currentCategory && (
            <AppListPanel
              category={this.state.currentCategory}
              largeHeader={this.state.currentCategory.largeHeader}
            />
          )}
        </Panel>
        <Panel
          ref={page => {
            this.refs.page = page;
          }}
          path="page"
          visible={false}
        >
          {initialized &&
            this.state.currentAppId && (
              <PagePanel
                appId={this.state.currentAppId}
                shouldDirectDownload={this.state.shouldDirectDownload}
              />
            )}
        </Panel>
        <Panel
          ref={updatePage => {
            this.refs.updatePage = updatePage;
          }}
          path="updatePage"
          visible={false}
        >
          {initialized &&
            this.state.currentUpdateMozAppManifestURL && (
              <UpdatePagePanel
                manifestURL={this.state.currentUpdateMozAppManifestURL}
              />
            )}
        </Panel>
        <Panel
          ref={settings => {
            this.refs.settings = settings;
          }}
          path="settings"
          visible={false}
        >
          <SettingsPanel />
        </Panel>
        <Panel
          ref={screenshot => {
            this.refs.screenshot = screenshot;
          }}
          path="screenshot"
          visible={false}
        >
          {this.state.images.length > 0 && (
            <ScreenshotPanel
              images={this.state.images}
              currentIndex={this.state.imageIndex}
              updateIndex={this.handleImageIndexUpdate}
            />
          )}
        </Panel>
        <Panel
          ref={search => {
            this.refs.search = search;
          }}
          path="search"
          visible={false}
        >
          <SearchPanel searchIn={this.state.searchIn} />
        </Panel>
        <Panel
          ref={update => {
            this.refs.update = update;
          }}
          path="update"
          visible={false}
        >
          {initialized && <UpdatePanel />}
        </Panel>
        <Panel
          ref={downloadHistory => {
            this.refs.downloadHistory = downloadHistory;
          }}
          path="download-history"
          visible={false}
        >
          <DownloadHistoryPanel />
        </Panel>
        <Panel
          ref={error => {
            this.refs.error = error;
          }}
          path="error"
          visible={false}
        >
          <ErrorPanel detail={this.state.errorPageDetail} />
        </Panel>
        <div
          id="category-bar"
          className={this.state.showCategoryBar ? '' : 'hidden'}
        >
          <kai-categorybar
            items={mainMenu}
            selected={this.state.currentSection}
            ref={this.categorybarRef}
            className={
              numberOfUpdatableApps > 0 && !hasVisitedMorePanel
                ? 'update-available-indicator'
                : ''
            }
          />
        </div>
        <kai-dialog
          dialogtitle={this.state.dialogOptions.title}
          message={this.state.dialogOptions.message}
          primarybtntext={this.state.dialogOptions.primarybtntext}
          secondarybtntext={this.state.dialogOptions.secondarybtntext}
          primarybtndisabled={!this.state.dialogOptions.primarybtntext}
          secondarybtndisabled={!this.state.dialogOptions.secondarybtntext}
          open={this.state.dialog}
          ref={this.dialogRef}
        />
      </div>
    );
  }
}

navigator.mozL10n.once(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
