import React, { Component } from 'react';

class Panel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activate: false,
    };
  }

  get path() {
    return this.props.path;
  }

  show() {
    this.element.classList.remove('dim');
    this.element.classList.remove('hide');
    this._focus();
  }

  hide() {
    if (!this.props.visible) {
      this.element.classList.remove('dim');
      this.element.classList.add('hide');
    }
    this._blur();
  }

  _focus() {
    this.setState({ activate: true });
  }

  _blur() {
    this.setState({ activate: false });
  }

  render() {
    const childrenWithProps = React.Children.map(
      this.props.children,
      function(child) {
        return React.cloneElement(child, {
          activate: this.state.activate,
        });
      }.bind(this)
    );
    let classList = 'panel';
    if (!this.props.visible) {
      classList += ' hide';
    }
    return (
      <div
        ref={panel => (this.element = panel)}
        className={classList}
        tabIndex="1"
      >
        {childrenWithProps}
      </div>
    );
  }
}

export default Panel;
