import React from 'react';
import ACTIONS from '../constants';

const getProgress = ({ progress, downloadSize, isDownloadPreparing }) => {
  if (isDownloadPreparing) return null;
  return Math.floor((progress / downloadSize) * 100);
};

const ProgressBar = ({ action, application }) => {
  const { isDownloadPreparing } = application.mozApp;
  if (action !== ACTIONS.STOP && !isDownloadPreparing)
    return <div className="divider" />;

  const progress = getProgress(application.mozApp);
  return (
    <div className="progress-wrapper">
      <kai-progress
        type={progress === null ? 'indeterminate' : 'determined'}
        percent={progress}
      />
    </div>
  );
};

export default ProgressBar;
